import React, {Component} from 'react';
import {Main as ScoreMain} from './views/scores/Main';
import './main.css';

export class App extends Component {
    componentDidMount() {
        document.title = "Huize Adonis - JorisP.nl";
        document.body.style.backgroundColor = "#ffffff";
    }

    render() {
        return (
            <div style={{
                fontFamily: 'Roboto'
            }}>
                <ScoreMain/>
            </div>
        )
    }
}
