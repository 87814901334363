import React from 'react';
import {
    Button,
    FormControl, NativeSelect,
    Paper,
    Typography,
} from '@material-ui/core/index'
import PropTypes from "prop-types";
import NewSeasonFAB from "./NewSeasonFAB";
import NewDriverDialog from "./NewDriverDialog";

const newSeason = require('../assets/new_season.json');

export default class NewSeason extends React.Component {
    state = {
        results: [],
        resultsFull: false,
        selectableDrivers: newSeason.drivers,
        currentFile: newSeason,
        showAlert: false,
    };

    static propTypes = {
        setFile: PropTypes.func.isRequired,
        setView: PropTypes.func.isRequired,
    };

    componentDidMount() {
        let initialResults = [];
        for (let i = 0; i < 2 * this.state.currentFile.teams.length; i++) {
            initialResults.push(["", ""]);
        }
        this.setState({results: initialResults})
    }

    updateResults = () => {
        const results = this.state.results;

        let cf = this.state.currentFile;

        for (let i = 0; i < results.length; i++) {
            for (let j = 0; j < cf.drivers.length; j++) {
                if (results[i][0] === cf.drivers[j].abbreviation) {
                    cf.drivers[j].team = results[i][1];
                }
            }
        }

        this.props.setFile(cf);
    };

    handleChange = (event) => {
        let value = JSON.parse(event.target.value);
        let results = this.state.results;
        let selectableDrivers = [];

        // Update Results
        results.splice(value.index, 1, value.driverTeam);

        // Update Selectable Drivers
        for (let i = 0; i < this.state.currentFile.drivers.length; i++) {
            selectableDrivers.push(this.state.currentFile.drivers[i])
        }

        for (let i = 0; i < results.length; i++) {
            let abbr = results[i][0];
            for (let j = 0; j < selectableDrivers.length; j++) {
                if (selectableDrivers[j].abbreviation === abbr) {
                    selectableDrivers.splice(j, 1);
                }
            }
        }

        this.setState({
            results: results,
            selectableDrivers: selectableDrivers
        });

        this.isResultsFull();
    };

    isResultsFull = () => {
        let results = this.state.results;
        for (let i = 0; i < results.length; i++) {
            if (results[i].toString() === ",") {
                this.setState({
                    resultsFull: false
                });
                return false;
            }
        }
        this.setState({
            resultsFull: true
        });
        return true;
    };

    getFullName = (abbr) => {
        if (abbr === "") {
            return ""
        } else {
            let cf = this.state.currentFile;

            for (let i = 0; i < cf.drivers.length; i++) {
                if (cf.drivers[i].abbreviation === abbr) {
                    return cf.drivers[i].name;
                }
            }
        }
    };

    showDriversInputs = (teamName, index) => {
        if (this.state.results.length === 0) {
            return(<div/>)
        } else {
            return [0, 1].map((key) => {
                let ind = 2 * index + key;
                return (
                    <FormControl key={key} variant="filled" style={{flex: 1, margin: 10, minWidth: 120, width: '100%'}}>
                        <NativeSelect
                            value={this.state.results[ind][0]}
                            onChange={this.handleChange}
                        >
                            <option style={{display: 'none'}}
                                    value="">{this.getFullName(this.state.results[ind][0])}</option>
                            {this.state.selectableDrivers.map((driver2, index2) => {
                                return (
                                    <option
                                        key={index2}
                                        value={'{"index": ' + ind + ', "driverTeam": ["' + driver2.abbreviation + '","' + teamName + '"] }'}
                                    >
                                        {driver2.name}
                                    </option>
                                )
                            })}
                        </NativeSelect>
                    </FormControl>
                )
            })
        }
    };

    addDriversToTeams = () => {
        let teams = newSeason.teams;

        return teams.map((team, index) => {
            return (
                <Paper key={index} style={{
                    width: '90%',
                    margin: 20,
                    padding: 20
                }}>
                    <Typography variant='h5' style={{
                        textDecoration: 'underline',
                        textDecorationColor: team.color,
                        textUnderlinePosition: 'under',

                    }}>
                        {team.name}
                    </Typography>
                    <div key={index} style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: 10
                    }}>
                        {this.showDriversInputs(team.name, index)}
                    </div>
                </Paper>
            )
        });
    };

    handlePrompt = (array) => {
        if (array[0]) {
            let name = array[1];
            let abbr = array[2];

            this.state.currentFile.drivers.push({
                "name": name,
                "abbreviation": abbr,
                "team": null,
                "points": [0]
            })
        }
    };

    render() {
        return (
            <div style={{
                backgroundColor: '#3a3a3a',
                borderRadius: 5,
                marginTop: 20,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
            }}>
                {this.addDriversToTeams()}
                <Button variant='contained' color='primary' disabled={!this.state.resultsFull} onClick={() => {
                    this.updateResults();
                    this.props.setView("main");
                }} style={{
                    margin: 20,
                }}>
                    Create Season
                </Button>
                <NewDriverDialog
                    showAlert={this.state.showAlert}
                    setAlert={(bool) => this.setState({showAlert: bool})}
                    promptResponse={this.handlePrompt}
                    currentFile={this.state.currentFile}
                />
                <NewSeasonFAB
                    showAlert={() => {this.setState({showAlert: true})}}
                />
            </div>
        )
    }
}
