import React, {Component} from 'react';

export default class Home extends Component {
    render() {
        return (
            <div>
                <p>Welcome on JorisP.nl! Work in progress...</p>
                <p>Projects</p>
                <ul>
                    <li><a href={'/projects/adonis'}>Rummikub</a></li>
                </ul>
            </div>
        )
    }
}
