import React, {Component} from 'react';
import {db} from '../../services/firebase';
import PropTypes from "prop-types";
import {useTable} from "react-table";
import Chart from 'react-apexcharts';
import AdonisButton from "./components/AdonisButton";
import AddScoreDialog from "./components/AddScoreDialog";
import Loader from "./components/Loader";
import {text} from "../../Text";
import Podium from "./components/Podium";

function Table({columns, data, showHeaderRow}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    })

    return (
        <table {...getTableProps()} style={{width: "100%"}}>
            <thead style={{display: showHeaderRow ? '' : 'none'}}>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

export class Game extends Component {
    colors = ['#008ffb', '#ff4560', '#00e396', '#feb019', '#775dd0']

    constructor(props) {
        super(props);

        this.state = {
            key: null,
            columns: null,
            data: null,
            dataTop: null,
            dataRemainder: null,
            showRemainder: false,
            windowWidth: window.outerWidth,
            game: null,
            showDialog: false,
            chart: {
                options: {
                    chart: {
                        id: 'basic-graph',
                        toolbar: {
                            show: false
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    colors: this.colors,
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    yaxis: {
                        min: 0,
                        forceNiceScale: true
                    }
                }
            }
        }
    }

    static propTypes = {
        gameId: PropTypes.string.isRequired,
        closeGame: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.getGameFromDB().then();

        window.onpopstate = e => {
            this.props.closeGame();
        }

        window.addEventListener('resize', () => this.setState({windowWidth: window.outerWidth}))
    }

    componentWillUnmount() {
        window.removeEventListener('resize', null)
    }

    toggleDialog = () => {
        this.setState({
            showDialog: !this.state.showDialog
        })
    }

    getGameFromDB = async () => {
        try {
            await db.ref('games/' + this.props.gameId).on("value", (snapshot) => {
                if (!snapshot.exists()) {
                    console.log("error");
                } else {
                    this.setState({
                        key: snapshot.key,
                        game: snapshot.val()
                    })
                    this.constructColumnsAndData(snapshot.val());
                }
            })
        } catch (error) {
            console.error("Failed to read database.")
        }
    }

    constructColumnsAndData = (values) => {
        if (values !== null) {
            const columns = [];
            const data = [];
            const series = [];

            for (let playersKey in values.players) {
                const player = values.players[playersKey];

                // Table data
                columns.push({
                    Header: <div>
                        <div style={{display: 'inline-block'}}>
                            {player.userName}
                        </div>
                        <div style={{
                            display: 'inline-block',
                            width: 10,
                            height: 10,
                            marginLeft: 5,
                            background: this.colors[playersKey],
                            borderRadius: 10
                        }}/>
                    </div>,
                    accessor: player.userName
                });

                for (let scoreKey in player.score) {
                    const score = player.score[scoreKey];

                    if (data[scoreKey] === undefined) {
                        data.push({});
                    }

                    let roundScore = scoreKey < player.score.length - 1 ? score - player.score[parseInt(scoreKey) + 1] : score;

                    data[scoreKey][player.userName] =
                        <div>
                            <div style={{display: "inline-block", marginRight: "2px"}}>
                                {score}
                            </div>
                            <div style={{display: "inline-block", fontSize: "12px", marginLeft: "2px"}}>
                                (+{roundScore})
                            </div>
                        </div>;
                }

                // Chart data
                const serie = {
                    name: player.userName,
                    data: player.score.reverse()
                }

                series.push(serie)
            }

            const chart = this.state.chart;
            chart.series = series

            const topSize = 5;

            this.setState({
                columns: columns,
                data: data,
                dataTop: data.slice(0, topSize),
                dataRemainder: data.slice(topSize, data.length),
                chart: chart
            })
        }
    }

    showDialog() {
        if (this.state.showDialog) {
            return (
                <AddScoreDialog
                    toggleDialog={this.toggleDialog}
                    gameId={this.props.gameId}
                    players={this.state.game.players}
                />
            )
        }
    }

    render() {
        if (this.state.data !== null && this.state.game !== null) {
            return (
                <div style={{
                    marginTop: 20,
                }}>
                    <div>
                        <Podium series={this.state.chart.series} />
                    </div>
                    <div style={{
                        marginTop: 20,
                        padding: 10,
                        borderRadius: 5,
                        boxShadow: '0px 1px 2px 1px #bbb'
                    }}>
                        <Table columns={this.state.columns} data={this.state.dataTop} showHeaderRow={true}/>
                        <div style={{
                            display: this.state.showRemainder ? '' : 'none',
                        }}>
                            <Table columns={this.state.columns} data={this.state.dataRemainder} showHeaderRow={false}/>
                        </div>
                        <div onClick={() => this.setState({showRemainder: !this.state.showRemainder})} style={{
                            background: '#eee',
                            margin: '0 -10px -10px -10px',
                            borderRadius: '0 0 5px 5px',
                            textAlign: 'center',
                            padding: 2,
                            fontSize: 18,
                            display: this.state.dataRemainder.length ? '' : 'none',
                        }}>
                            {this.state.showRemainder ? '˄' : '˅'}
                        </div>
                    </div>
                    <div style={{
                        marginTop: 20,
                        padding: 10,
                        borderRadius: 5,
                        boxShadow: '0px 1px 2px 1px #bbb'
                    }}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Chart
                                options={this.state.chart.options}
                                series={this.state.chart.series}
                                type='line'
                                width={0.9 * this.state.windowWidth}
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 30
                    }}>
                        <AdonisButton text={text.add_score} onClick={this.toggleDialog}/>
                    </div>
                    {this.showDialog()}
                </div>
            )
        } else {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '100px'
                }}>
                    <Loader/>
                </div>
            )
        }
    }
}
