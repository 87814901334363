import React, { Component } from 'react';
import {Wrapper} from "./Wrapper";
import {createMuiTheme, CssBaseline, MuiThemeProvider} from "@material-ui/core/index";

export class App extends Component {
    state = {
        muiTheme: createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: '#FF1801'
                }
            }
        })
    };

    render() {
        return (
            <MuiThemeProvider theme={this.state.muiTheme}>
                <CssBaseline/>
                <Wrapper />
            </MuiThemeProvider>
        );
    }
}
