import React, {Component} from 'react';

export default class Loader extends Component {
    render() {
        return (
            <div style={{
                border: '4px solid #ddd',
                borderTop: '4px solid #42a5f5',
                borderRadius: '50%',
                width: 20,
                height: 20,
                animation: 'spin 1s linear infinite'
            }}/>
        )
    }
}
