import React from 'react';
import PropTypes from "prop-types";
import {getPlaylistItems} from "../../api/SpotifyAPI";
import './SpotifyPlaylistFetcher.css';
import BingoChart from "../BingoChart";
import html2canvas from 'html2canvas';

export default class SpotifyPlaylistFetcher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            tracksTextArea: "",
            sliderValue: 5,
            tracks: [],
            code: "",
            isFetching: false,
            chart: null,
        }
    }

    static propTypes = {
        token: PropTypes.string.isRequired,
        generateChart: PropTypes.func.isRequired,
        code: PropTypes.string.isRequired,
        newBackgroundColor: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.code.includes("null") && this.state.code !== "#" + this.props.code) {
            this.setState({
                code: "#" + this.props.code,
            })
        }
    }

    onChange = (event) => {
        let value = event.target.value;
        this.setState({value});
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        const value = this.state.value;
        const valueSplit = value.split(":");
        const playlistId = valueSplit[valueSplit.length - 1];

        await this.setState({
            isFetching: true,
        });

        let items;

        try {
            items = await getPlaylistItems(this.props.token, playlistId);

            await this.setState({
                tracks: [],
                tracksTextArea: "",
                isFetching: false,
            });

            this.tracksToTextArea(items)
        } catch (e) {
            await this.setState({
                tracks: [],
                tracksTextArea: "",
                isFetching: false,
            });

            window.alert("Invalid Spotify URI");
        }
    };

    tracksToTextArea(items) {
        let text = "";

        items.forEach((item) => {
            const track = item.track.name;

            let artists = "";

            for (let i = 0; i < item.track.artists.length; i++) {
                if (i === 0) {
                    artists += item.track.artists[i].name
                } else if (i !== item.track.artists.length - 1) {
                    artists += ", " + item.track.artists[i].name
                } else {
                    artists += " & " + item.track.artists[i].name
                }
            }

            this.setState({
                tracks: [...this.state.tracks, {
                    title: track,
                    description: artists,
                    checked: false,
                }]
            });

            text += track + " | " + artists + "\n"
        });

        this.setState({
            tracksTextArea: text,
        })
    }

    generateBingoChart = async () => {
        const tracks = [];
        const chartSize = this.state.sliderValue;

        for (let i = 0; i < chartSize * chartSize; i++) {
            let randomInt = Math.floor(Math.random() * this.state.tracks.length);

            if (!tracks.includes(this.state.tracks[randomInt])) {
                tracks.push(this.state.tracks[randomInt]);
            } else {
                --i;
            }
        }

        const data = {
            tracks: tracks,
            chartSize: chartSize
        };

        await this.props.generateChart(data);

        this.setState({
            chart: {
                elements: tracks,
                size: chartSize,
            }
        })

        this.generateImageOfChart();
    };

    generateImageOfChart = () => {
        // Get bingo chart
        const element = document.getElementById("tableWrapper");
        element.style.display = "block";

        const color = document.body.style.backgroundColor.replace(')', ', 0.75)').replace('rgb', 'rgba');

        // Make screenshot of chart
        html2canvas(element, {backgroundColor: color, scale: 1.5}).then(function (canvas) {
            element.style.display = "none";

            const canvasImage = document.getElementById("canvasImage");

            while (canvasImage.lastChild !== null) {
                canvasImage.removeChild(canvasImage.lastElementChild);
            }

            canvasImage.appendChild(canvas);
        });
    }

    render() {
        if (this.state.chart === null) {
            return (
                <div id="newBingoWrapper" style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}>
                    <ol>
                        <li>Open Spotify op je pc.</li>
                        <li>
                            Links in beeld doe je het volgende:<br/>
                            <ol>
                                <li style={{margin: 0}}>Ga met je muis boven de afspeellijst die je wilt gebruiken.</li>
                                <li style={{margin: 0}}>Rechtermuisknop en dan <i>Delen > Kopieer Spotify URI</i>.</li>
                            </ol>
                        </li>
                        <form onSubmit={this.handleSubmit}>
                            <li>
                                Klik op het tekst vlak hieronder en doe <code>CTRL+V</code>.<br/>
                                <input
                                    type="text"
                                    placeholder="spotify:playlist:123abc456def789ghi"
                                    required={true}
                                    value={this.state.value}
                                    onChange={this.onChange}
                                    style={{
                                        width: 300,
                                        padding: 8,
                                        borderRadius: 8,
                                        border: "none",
                                        margin: 8
                                    }}
                                />
                            </li>
                            <li>
                                Klik op deze knop:<br/>
                                <button type="submit" disabled={this.state.isFetching || this.state.value === ""}
                                        style={{
                                            padding: 8,
                                            borderRadius: 8,
                                            margin: 8,
                                            border: "none",
                                        }}>Liedjes Opvragen
                                </button>
                                <div id="loader" style={{display: this.state.isFetching ? "inline-block" : "none"}}/>
                            </li>
                        </form>
                        <li>
                            De liedjes verschijnen hieronder: ({this.state.tracks.length} liedjes)<br/>
                            <textarea disabled={true} value={this.state.tracksTextArea} style={{
                                resize: "none",
                                padding: 8,
                                borderRadius: 8,
                                margin: 8,
                                width: 400,
                                height: 200
                            }}/>
                        </li>
                        <li>
                            Kies de bingokaart grootte: ({this.state.sliderValue})<br/>
                            <input id="slider" type="range" min={2} max={7} value={this.state.sliderValue}
                                   onChange={(event) => {
                                       this.setState({sliderValue: event.currentTarget.value})
                                   }}/>
                        </li>
                        <li>
                            Klik op deze knop:<br/>
                            <button disabled={this.state.tracksTextArea === ""} onClick={this.generateBingoChart}
                                    style={{
                                        padding: 8,
                                        borderRadius: 8,
                                        margin: 8,
                                        border: "none"
                                    }}>Genereer Een Bingo Kaart
                            </button>
                        </li>
                        {/*<li>*/}
                        {/*    Deel de link <code>projects.jorisp.nl/bingo</code> en deze code:<br/>*/}
                        {/*    <div children={this.state.code} style={{*/}
                        {/*        backgroundColor: '#ffffff55',*/}
                        {/*        margin: 8,*/}
                        {/*        padding: 8,*/}
                        {/*        borderRadius: 8,*/}
                        {/*        fontSize: 32,*/}
                        {/*        display: "inline-block"*/}
                        {/*    }}/>*/}
                        {/*</li>*/}
                    </ol>
                </div>
            )
        } else {
            return (
                <div>
                    <div style={{zoom: 0.5}} id="canvasImage"/>
                    <div style={{width: 500}}>
                        <BingoChart code={this.state.code} chart={this.state.chart}/>
                    </div>
                    <ol start="8" style={{
                        fontFamily: "sans-serif",
                        fontSize: "20px",
                    }}>
                        <li>
                            Rechtermuisknop op de bingo kaart hierboven en dan op <i>Afbeelding Kopiëren</i>.
                        </li>
                        <li>
                            Wil je de kaart via WhatsApp versturen?<br/>
                            <ol>
                                <li style={{margin: 0}}>Open WhatsApp op de computer in een nieuw tabblad.</li>
                                <li style={{margin: 0}}>Selecteer de persoon waar je de kaart naar toe wil sturen.</li>
                                <li style={{margin: 0}}>Klik op het tekstvak voor een nieuw bericht.</li>
                                <li style={{margin: 0}}>Doe <code>CTRL+V</code>.</li>
                                <li style={{margin: 0}}>Klik op <code>ENTER</code></li>
                            </ol>
                            <br/>
                            Wil je de kaart via iets anders versturen?<br/>
                            <ol>
                                <li style={{margin: 0}}>Open je mailbox op de computer in een nieuw tabblad.</li>
                                <li style={{margin: 0}}>Maak een nieuwe mail aan die je verstuurt naar jezelf.</li>
                                <li style={{margin: 0}}>In het bericht vak van de mail doe je <code>CTRL+V</code>.</li>
                                <li style={{margin: 0}}>Als je alle kaarten hebt, verzend je de mail.</li>
                                <li style={{margin: 0}}>Nu kan je op je telefoon vanuit de mail de kaarten doorsturen
                                    hoe je zelf wilt.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Klik op deze knop voor een nieuwe kaart:<br/>
                            <div>
                                <button onClick={async () => {
                                    await this.props.newBackgroundColor()
                                    await this.generateBingoChart()
                                }}
                                        style={{
                                            padding: 8,
                                            borderRadius: 8,
                                            margin: 8,
                                            border: "none"
                                        }}>Genereer Een Nieuwe Bingo Kaart
                                </button>
                            </div>
                        </li>
                    </ol>
                </div>
            )
        }
    }
}
