import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class Answer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answer: ""
        }
    }

    static propTypes = {
        questionNumber: PropTypes.number.isRequired,
        question: PropTypes.string.isRequired,
        answerStyle: PropTypes.string.isRequired,
        drivers: PropTypes.array,
        lapCount: PropTypes.number,
        updateAnswer: PropTypes.func.isRequired
    }

    createAnswerOption(answerStyle) {
        switch (answerStyle) {
            case "drivers":
                return (
                    <select onChange={this.handleChange} defaultValue='none'>
                        <option value="none" disabled hidden />
                        {this.props.drivers.map((driver, index) => {
                            const name = driver.firstName + " " + driver.lastName
                            return <option key={index} value={name}>{name}</option>
                        })}
                    </select>
                )
            case 'lap':
                return (
                    <div>
                        <input
                            type="number"
                            placeholder="0"
                            min={0}
                            max={this.props.lapCount}
                            value={this.state.answer}
                            onChange={this.handleChange}
                        />
                        /{this.props.lapCount}
                    </div>
                )
        }
    }

    handleChange = (event) => {
        this.setState({answer: event.target.value})
        this.props.updateAnswer(this.props.questionNumber, event.target.value)
    }

    render() {
        return (
            <div style={{
                border: '1px solid black',
                marginBottom: 50
            }}>
                <p>{this.props.question}</p>
                <form>
                    {this.createAnswerOption(this.props.answerStyle)}
                </form>
            </div>
        )
    }
}
