import React, {Component} from 'react';
import PropTypes from "prop-types";
import {getAnswers, getCircuitInfo} from "../firebase/db";

export default class CircuitAnswers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            circuit: {},
            answers: []
        }
    }

    static propTypes = {
        raceId: PropTypes.string.isRequired
    }

    componentDidMount() {
        getCircuitInfo(this.props.raceId, this.handleCircuitInfo).then()
        getAnswers(this.props.raceId, this.handleAnswers).then()
    }

    handleCircuitInfo = (circuit) => {
        console.log(circuit)
        this.setState({
            circuit: circuit
        })
    }

    handleAnswers = (answers) => {
        console.log(answers)
        this.setState({
            answers: answers
        })
    }

    showQuestions() {
        const questions = this.state.circuit.questions;
        const answers = this.state.answers;

        console.log(questions)

        return questions.map((question, index) => {
            return (
                <div key={index} style={{
                    border: '1px solid black',
                    marginBottom: 10
                }}>
                    <span style={{fontWeight: 'bold'}}>{question.question}</span>
                    {answers.map((answer, key) => {
                        return (
                            <div key={key}>
                                {answer[0]}: {answer[1][index]}
                            </div>
                        )
                    })}
                </div>
            )
        })
    }

    render() {
        if (Object.keys(this.state.circuit).length === 0) {
            return <div>Loading...</div>
        } else {
            return (
                <div>
                    <div style={{
                        border: '1px solid black'
                    }}>
                        <span style={{fontWeight: 'bold'}}>{this.state.circuit.location}</span> <br/>
                        Lap count: {this.state.circuit.lapCount}
                    </div>
                    <div>
                        <p>Questions</p>
                        {this.showQuestions()}
                    </div>
                </div>
            )
        }
    }
}
