import React, {Fragment} from 'react';
import {
    AddResult,
    DriverTransfer,
    ResultFAB,
    ResultsTableDrivers,
    ResultsTableTeams
} from './index.js'
import {
    Typography
} from "@material-ui/core/index";
import PropTypes from "prop-types";

export default class Results extends React.Component {
    state = {
        activeView: 'table',
    };

    static propTypes = {
        currentFile: PropTypes.object.isRequired,
        setFile: PropTypes.func.isRequired,
    };

    setView = (view) => {
        this.setState({
            activeView: view
        })
    };

    showScreen = () => {
        switch (this.state.activeView) {
            case "table":
                return (
                    <div>
                        <Typography variant='h5'>
                            Results
                        </Typography>
                        <ResultsTableDrivers currentFile={this.props.currentFile}/>
                        <ResultsTableTeams currentFile={this.props.currentFile}/>
                        <ResultFAB
                            setView={this.setView}
                        />
                    </div>
                );
            case "addRace":
                return (
                    <Fragment>
                        <Typography variant='h5'>
                            Add Race Result
                        </Typography>
                        <AddResult
                            currentFile={this.props.currentFile}
                            setFile={this.props.setFile}
                            setView={this.setView}
                        />
                    </Fragment>
                );
            case "driverTransfer":
                return (
                    <Fragment>
                        <Typography variant='h5'>
                            Driver Transfer
                        </Typography>
                        <DriverTransfer
                            currentFile={this.props.currentFile}
                            setFile={this.props.setFile}
                            setView={this.setView}
                        />
                    </Fragment>
                );
            default:
                return <div/>
        }
    };

    render() {
        return (
            <Fragment>
                {this.showScreen()}
            </Fragment>
        )
    }
}
