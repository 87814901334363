import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBptwLP4ot_0d-zK_ebTr0kDCZEf8V3qeo",
    authDomain: "jorisp-nl.firebaseapp.com",
    databaseURL: "https://jorisp-nl.firebaseio.com",
    projectId: "jorisp-nl",
    storageBucket: "jorisp-nl.appspot.com",
    messagingSenderId: "269370885814",
    appId: "1:269370885814:web:800f77ed7d5c760ed54817",
    measurementId: "G-26NRGTWC4T"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// export const analytics = firebase.analytics();
export const auth = firebase.auth;
export const db = firebase.database();
