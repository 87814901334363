import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyAkjKpDP0pxHuOFcFUzPh8h6lpUfxLjOZY",
    authDomain: "rummikubscore.firebaseapp.com",
    databaseURL: "https://rummikubscore.firebaseio.com",
    projectId: "rummikubscore",
    storageBucket: "rummikubscore.appspot.com",
    messagingSenderId: "330336397692",
    appId: "1:330336397692:web:2335144a030b64fa455cf9"
};

const adonisFirebaseApp = firebase.initializeApp(firebaseConfig, 'Adonis');

export const db = adonisFirebaseApp.database();