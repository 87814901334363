import React from 'react';
import PropTypes from "prop-types";
import {createArrow, createCirclePiece} from "./SpinnerUtils";

export default class Spinner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            wheelValues: []
        }
    }

    static propTypes = {
        values: PropTypes.array.isRequired,
        nrOfPieces: PropTypes.number.isRequired,
        spinWheel: PropTypes.bool.isRequired,
        setParentState: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const wrapper = document.getElementById("canvasWrapper");
        wrapper.innerHTML = "";

        this.createWheel();
        createArrow();

        if (this.props.spinWheel) {
            this.props.setParentState("spinWheel", false);
            this.setState({
                wheelValues: [],
            });

            this.createWheel();

            const deg = Math.floor(Math.random() * 3600 + 1800);

            wrapper.style.transition = 'transform 10s cubic-bezier(0, 0.55, 0.45, 1)';
            wrapper.style.transform = 'rotate(' + deg + 'deg)';

            setTimeout(() => {
                const newDeg = deg % 360;
                wrapper.style.transition = 'transform 0s ease';
                wrapper.style.transform = 'rotate(' + newDeg + 'deg)';

                const wheelPercentage = 100 - newDeg / 3.6;

                const wheelValues = this.state.wheelValues;

                let winningValue = {
                    value: "EASTER EGG",
                    percentage: 0
                };

                for (let i = wheelValues.length - 1; i >= 0; i--) {
                    if (wheelPercentage < wheelValues[i].percentage) {
                        winningValue = this.props.values[i % this.props.values.length];
                    } else {
                        break;
                    }
                }

                this.props.setParentState('spinnerValue', winningValue);

                this.setState({
                    wheelValues: []
                });
            }, 10000)
        }
    }

    createWheel() {
        const values = this.props.values;
        const nrOfPieces = this.props.nrOfPieces;

        let totalPercentage = 0;

        for (let i = 0; i < values.length * nrOfPieces; i++) {
            const index = i % values.length;
            const percentage = values[index].percentage / nrOfPieces;

            if (this.state.wheelValues.length < values.length * nrOfPieces) {
                const wheelValues = this.state.wheelValues;

                wheelValues.push({
                    value: values[index].value,
                    percentage: percentage + totalPercentage,
                });

                this.setState({
                    wheelValues: wheelValues,
                });
            }

            if (percentage > 0) {
                const canvas = createCirclePiece(125, 125, 120, percentage, values[index].value);

                const angle = totalPercentage * 3.6;
                canvas.style.transform = 'rotate(' + angle + 'deg)';
                totalPercentage += percentage;
            }
        }
    }

    render() {
        return (
            <div id="spinnerWrapper" style={{width: 250, height: 250, position: 'relative', margin: '50px 0'}}>
                <div id="canvasWrapper" style={{width: '100%', height: '100%', position: 'absolute', overflow: 'hidden'}}/>
                <div id="arrowWrapper" style={{width: '100%', height: '100%', position: 'absolute'}}/>
            </div>
        )
    }
}
