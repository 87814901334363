import React from 'react';
import {
    Button,
    FormControl,
    NativeSelect,
    Typography
} from '@material-ui/core/index'
import PropTypes from "prop-types";

export default class AddResult extends React.Component {
    state = {
        results: [],
        resultsFull: false,
        selectableDrivers: this.props.currentFile.drivers,
        fastestLapIndex: 0,
    };

    static propTypes = {
        currentFile: PropTypes.object.isRequired,
        setFile: PropTypes.func.isRequired,
        setView: PropTypes.func.isRequired,
    };

    componentDidMount() {
        let initialResults = [];
        let drivers = [];
        let cf = this.props.currentFile;

        for (let i = 0; i < cf.drivers.length; i++) {
            if (cf.drivers[i].team !== null) {
                drivers.push(cf.drivers[i]);
            }
        }

        for (let i = 0; i < 2 * cf.teams.length; i++) {
            initialResults.push(["", ""]);
        }

        this.setState({
            selectableDrivers: drivers,
            results: initialResults
        })
    }

    // Final method
    updateResults = () => {
        const results = this.state.results;
        let cf = this.props.currentFile;

        // Get next empty circuit
        let circuitIndex = 0;
        while (cf.results[circuitIndex].results.length > 0 && circuitIndex < cf.results.length - 1) {
            circuitIndex++;
        }

        // Set results
        cf.results[circuitIndex].results = results;

        // Set fastest lap
        cf.results[circuitIndex].fastestLap = results[this.state.fastestLapIndex][0];

        // Update points
        for (let i = 0; i < results.length; i++) {
            for (let j = 0; j < cf.drivers.length; j++) {
                let driver = cf.drivers[j];

                if (driver.abbreviation === results[i][0]) {
                    let validFastestLap = driver.abbreviation === results[this.state.fastestLapIndex][0] && i < 10;

                    // Add driver's points
                    driver.points[circuitIndex + 1] = driver.points[circuitIndex] + this.calcDriverPoints(i, validFastestLap);

                    // Add team's points
                    for (let k = 0; k < cf.teams.length; k++) {
                        let team = cf.teams[k];

                        if (driver.team === team.name) {
                            if (team.points[circuitIndex + 1] === undefined) {
                                team.points[circuitIndex + 1] = team.points[circuitIndex] + this.calcDriverPoints(i, validFastestLap);
                            } else {
                                team.points[circuitIndex + 1] = team.points[circuitIndex + 1] + this.calcDriverPoints(i, validFastestLap);
                            }
                        }
                    }
                }

                // Keep the amount of points the same for a driver without a team
                if (driver.team === null) {
                    driver.points[circuitIndex + 1] = driver.points[circuitIndex];
                }
            }
        }

        this.props.setFile(cf);
    };

    calcDriverPoints = (pos, fastestLap) => {
        let points = [25, 18, 15, 12, 10, 8, 6, 4, 2, 1];

        if (pos > 9) {
            return 0
        } else {
            return fastestLap ? points[pos] + 1 : points[pos];
        }
    };

    handleChange = (event) => {
        let value = JSON.parse(event.target.value);
        let results = this.state.results;
        let selectableDrivers = [];
        let cf = this.props.currentFile;

        // Update Results
        results.splice(value.index, 1, value.driverTeam);

        // Update Selectable Drivers
        for (let i = 0; i < this.props.currentFile.drivers.length; i++) {
            if (cf.drivers[i].team !== null) {
                selectableDrivers.push(cf.drivers[i]);
            }
        }

        for (let i = 0; i < results.length; i++) {
            let abbr = results[i][0];
            for (let j = 0; j < selectableDrivers.length; j++) {
                if (selectableDrivers[j].abbreviation === abbr) {
                    selectableDrivers.splice(j, 1);
                }
            }
        }

        this.setState({
            results: results,
            selectableDrivers: selectableDrivers
        });

        this.isResultsFull();
    };

    isResultsFull = () => {
        let results = this.state.results;
        for (let i = 0; i < results.length; i++) {
            if (results[i].toString() === ",") {
                this.setState({
                    resultsFull: false
                });
                return false;
            }
        }
        this.setState({
            resultsFull: true
        });
        return true;
    };

    getFullName = (abbr) => {
        if (abbr === "") {
            return ""
        } else {
            let cf = this.props.currentFile;

            for (let i = 0; i < cf.drivers.length; i++) {
                if (cf.drivers[i].abbreviation === abbr) {
                    return cf.drivers[i].name;
                }
            }
        }
    };

    createPositions = () => {
        if (this.state.results.length === 0) {
            return(<div/>)
        } else {
            let cf = this.props.currentFile;
            let drivers = [];

            for (let i = 0; i < cf.drivers.length; i++) {
                if (cf.drivers[i].team !== null) {
                    drivers.push(cf.drivers[i]);
                }
            }

            return (
                <div style={{flex: 1, width: '100%', paddingRight: 20}}>{
                    drivers.map((driver, index) => {
                        let indexStyle = {
                            display: 'inline',
                            marginLeft: 20,
                            marginRight: 10,
                            flex: 0.1,
                            textAlign: 'center',
                            padding: '0px 3px',
                        };

                        if (index === this.state.fastestLapIndex) {
                            indexStyle['backgroundColor'] = '#ad3add';
                            indexStyle['borderRadius'] = 5;
                        }

                        return (
                            <div key={index} style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Typography variant='h6'
                                            style={indexStyle}
                                            onClick={() => {
                                                this.setState({
                                                    fastestLapIndex: index,
                                                });
                                            }}>
                                    {index + 1}.
                                </Typography>
                                <FormControl variant="filled" style={{flex: 1, margin: 10, minWidth: 120}}>
                                    <NativeSelect
                                        value={this.state.results[index][0]}
                                        onChange={this.handleChange}
                                    >
                                        <option style={{display: 'none'}}
                                                value="">{this.getFullName(this.state.results[index][0])}</option>
                                        {this.state.selectableDrivers.map((driver2, index2) => {
                                            return (
                                                <option
                                                    key={index2}
                                                    value={'{"index": ' + index + ', "driverTeam": ["' + driver2.abbreviation + '","' + driver2.team + '"] }'}
                                                >
                                                    {driver2.name}
                                                </option>
                                            )
                                        })}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                        )
                    })
                }</div>
            );
        }
    };

    render() {
        return (
            <div style={{
                backgroundColor: '#424242',
                borderRadius: 5,
                marginTop: 20,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
            }}>
                {this.createPositions()}
                <Button variant='contained' color='primary' disabled={!this.state.resultsFull} onClick={() => {
                    this.updateResults();
                    this.props.setView("table");
                }} style={{
                    margin: 20,
                }}>
                    Add Result
                </Button>
            </div>
        )
    }
}
