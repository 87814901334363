import React, {Component} from 'react';
import Spinner from "./components/Spinner/Spinner";
import Options from "./components/Options/Options";
import WinnerPrompt from "./components/WinnerPrompt/WinnerPrompt";

export class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: [],
            nrOfPieces: 1,
            spinWheel: false,
            spinWheelButton: false,
            spinnerValue: undefined,
            slideIn: false,
        }
    }

    componentDidMount() {
        // document.body.style.margin = "0";
        document.title = "Spinner - JorisP.nl";
        document.body.style.backgroundColor = "#0d47a1";
    }

    setParentState = (key, value) => {
        if (key === "values") {
            let values = [];
            for (let i = 0; i < value.length; i++) {
                values.push({
                    value: value[i].value,
                    percentage: value[i].percentage
                })
            }
            this.setState({
                values: values
            })
        } else {
            this.setState({
                [key]: value
            })
        }
    };

    spinWheelButton = () => {
        let toggle = true;

        const values = this.state.values;

        for (let i = 0; i < values.length; i++) {
            const value = values[i];

            if (value.value === "") {
                alert("There is still an empty value!");
                toggle = false;
                break;
            }
        }

        if (toggle) {
            this.setState({
                spinWheel: true,
                spinWheelButton: true,
            });
            setTimeout(() => {
                this.setState({
                    spinWheelButton: false,
                })
            }, 2000)
        }
    };

    render() {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
            }}>
                <WinnerPrompt
                    spinnerValue={this.state.spinnerValue}
                    setParentState={this.setParentState}
                />
                <Spinner
                    values={this.state.values}
                    nrOfPieces={this.state.nrOfPieces}
                    spinWheel={this.state.spinWheel}
                    setParentState={this.setParentState}
                />
                <div>
                    <button
                        type="button"
                        disabled={this.state.spinWheel}
                        onClick={this.spinWheelButton}
                        style={{
                            width: 270,
                            padding: 16,
                            backgroundColor: '#2e7b32',
                            borderRadius: 8,
                            border: 'none',
                            outline: 'none',
                            color: '#ffffff',
                            fontSize: 32
                        }}
                    >
                        Spin The Wheel!
                    </button>
                </div>
                <div style={{
                    backgroundColor: '#3d6db4',
                    width: '90%',
                    margin: '50px 0',
                    borderRadius: 16,
                    overflow: 'hidden',
                }}>
                    <Options
                        values={this.state.values}
                        nrOfPieces={this.state.nrOfPieces}
                        setParentState={this.setParentState}
                    />
                </div>
            </div>
        )
    }
}
