import React from "react";

export function Wreckfest() {
    const [randomTrack, setRandomTrack] = React.useState({});

    function generate() {
        let r1 = Math.floor(Math.random() * tracks.length);
        let map = tracks[r1];
        let r2 = Math.floor(Math.random() * map.tracks.length);
        let track = map.tracks[r2];

        setRandomTrack({map: map, track: track});
    }

    function showGeneratedTrack() {
        if (randomTrack.track !== undefined) {
            return (
                <div>
                <div style={{
                    border: "1px solid black",
                    background: "#aaa",
                    marginBottom: 20,
                    padding: 16,
                    borderRadius: 5,
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                }}>
                    <div style={{fontSize: 20, marginBottom: 12, fontWeight: "bold"}}>
                        {randomTrack.map.name}
                    </div>
                    <div>
                        <div style={{
                            display: "flex",
                            borderTop: "1px solid black",
                            paddingTop: "5px",
                        }}>
                            <div style={{flex: 1}}>
                                <img src={randomTrack.track.thumbnail} style={{width: "100%"}}
                                     alt={randomTrack.track.name}/>
                            </div>
                            <div style={{
                                flex: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginLeft: "4px"
                            }}>
                                <div style={{fontSize: 16}}>
                                    {randomTrack.track.name}
                                </div>
                                <div style={{fontSize: 14, fontStyle: "italic"}}>
                                    {randomTrack.track.length} m
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div style={{borderBottom: "1px solid black", marginBottom: 20}}/>
                </div>
            )
        } else {
            return <div/>
        }
    }

    return (
        <div>
            <div className={"generateButton"} onClick={generate}>
                RANDOM TRACK
            </div>
            {showGeneratedTrack()}
            {tracks.map((track, index) => {
                return (
                    <div key={index} style={{
                        border: "1px solid black",
                        background: "#aaa",
                        marginBottom: 20,
                        padding: 16,
                        borderRadius: 5,
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                    }}>
                        <div style={{fontSize: 20, marginBottom: 12, fontWeight: "bold"}}>
                            {track.name}
                        </div>
                        <div>
                            {track.tracks.map((t, i) => {
                                return (
                                    <div key={i} style={{
                                        display: "flex",
                                        borderTop: "1px solid black",
                                        paddingTop: "5px",
                                    }}>
                                        <div style={{flex: 1}}>
                                            <img src={t.thumbnail} style={{width: "100%"}} alt={t.name}/>
                                        </div>
                                        <div style={{
                                            flex: 2,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            marginLeft: "4px"
                                        }}>
                                            <div style={{fontSize: 16}}>
                                                {t.name}
                                            </div>
                                            <div style={{fontSize: 14, fontStyle: "italic"}}>
                                                {t.length} m
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const tracks = [{
    name: "Big Valley Speedway",
    tracks: [{
        name: "Figure 8",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/63/Speedway2_figure_8.png",
        length: 670
    }, {
        name: "Inner Oval",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/a1/Speedway2_inner_oval.png",
        length: 562
    }, {
        name: "Outer Oval",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/b/b2/Speedway2_outer_oval.png",
        length: 756
    }, {
        name: "Outer Oval Loop",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/e/e7/Speedway2_oval_loop.png",
        length: 1650
    }]
}, {
    name: "Bleak City",
    tracks: [{
        name: "Race Track",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/5/55/Crm01_1.png",
        length: 3440
    }, {
        name: "Race Track Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/d/d2/Crm01_5.png",
        length: 3440
    }]
}, {
    name: "Bloomfield Speedway",
    tracks: [{
        name: "Dirt Oval",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/0/00/Dirt_speedway_dirt_oval.png",
        length: 406
    }, {
        name: "Figure 8",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/5/5f/Dirt_speedway_figure_8.png",
        length: 495
    }]
}, {
    name: "Bonebreaker Valley",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/2/25/Bonebreaker_valley_main_circuit.png",
        length: 848
    }]
}, {
    name: "Boulder Bank Circuit",
    tracks: [{
        name: "Main Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/a8/Sandpit3_long_loop.png",
        length: 2880
    }, {
        name: "Main Route Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/9/9d/Sandpit3_long_loop_rev.png",
        length: 2880
    }, {
        name: "Short Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/8/8e/Sandpit3_short_loop.png",
        length: 2340
    }, {
        name: "Short Route Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/67/Sandpit3_short_loop_rev.png",
        length: 2340
    }]
}, {
    name: "Clayridge Circuit",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/d/d6/Mixed9_r1.png",
        length: 1160
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/f/f7/Mixed9_r1_rev.png",
        length: 1160
    }]
}, {
    name: "Crash Canyon",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/5/50/Crash_canyon_main_circuit.png",
        length: 1010
    }]
}, {
    name: "Deathloop",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/2/21/Loop.png",
        length: 1180
    }]
}, {
    name: "Devil's Canyon",
    tracks: [{
        name: "Race Track",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/6f/Crm02_1.png",
        length: 2620
    }]
}, {
    name: "Dirt Devil Stadium",
    tracks: [{
        name: "Dirt Speedway",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/7/7b/Triangle_r1.png",
        length: 992
    }]
}, {
    name: "Drytown Desert Circuit",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/2/25/Fields08_1.png",
        length: 960
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/e/e5/Fields08_1_rev.png",
        length: 960
    }]
}, {
    name: "Eagles Peak Motorpark",
    tracks: [{
        name: "Racing Track",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/0/07/Fields13_1.png",
        length: 2010
    }, {
        name: "Racing Track Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/f/f1/Fields13_1_rev.png",
        length: 2010
    }]
}, {
    name: "Espedalen Raceway",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/a8/Tarmac3_main_circuit.png",
        length: 2280
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/0/02/Tarmac3_main_circuit_rev.png",
        length: 2280
    }, {
        name: "Short Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/3/32/Tarmac3_short_circuit.png",
        length: 1050
    }, {
        name: "Short Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/c/ce/Tarmac3_short_circuit_rev.png",
        length: 1050
    }]
}, {
    name: "FinnCross Circuit",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/8/86/Mixed1_main_circuit.png",
        length: 944
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/66/Mixed1_main_circuit_rev.png",
        length: 1040
    }]
}, {
    name: "Fire Rock Raceway",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/e/e8/Tarmac1_main_circuit.png",
        length: 1960
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/9/90/Tarmac1_main_circuit_rev.png",
        length: 1960
    }, {
        name: "Short Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/5/5d/Tarmac1_short_circuit.png",
        length: 1540
    }, {
        name: "Short Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/b/b6/Tarmac1_short_circuit_rev.png",
        length: 1540
    }]
}, {
    name: "Firwood Motocenter",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/ad/Mixed7_r1.png",
        length: 1460
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/3/3a/Mixed7_r1_rev.png",
        length: 1460
    }, {
        name: "Rally Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/1/12/Mixed7_r2.png",
        length: 1520
    }, {
        name: "Rally Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/2/21/Mixed7_r2_rev.png",
        length: 1520
    }, {
        name: "Short Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/4/4d/Mixed7_r3.png",
        length: 1090
    }, {
        name: "Short Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/b/b0/Mixed7_r3_rev.png",
        length: 1090
    }]
}, {
    name: "Hellride",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/8/89/Urban06.png",
        length: 1610
    }]
}, {
    name: "Hillstreet Circuit",
    tracks: [{
        name: "Race Track",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/69/Urban08_1.png",
        length: 1710
    }, {
        name: "Race Track Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/3/35/Urban08_1_rev.png",
        length: 1710
    }]
}, {
    name: "Hilltop Stadium",
    tracks: [{
        name: "Figure 8",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/1/10/Speedway1_figure_8.png",
        length: 1010
    }, {
        name: "Oval",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/e/e4/Speedway1_oval.png",
        length: 820
    }]
}, {
    name: "Kingston Raceway",
    tracks: [{
        name: "Asphalt Oval",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/ae/Fields12_1.png",
        length: 443
    }, {
        name: "Asphalt Oval Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/0/04/Fields12_1_rev.png",
        length: 443
    }, {
        name: "Figure 8",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/b/b2/Fields12_2.png",
        length: 469
    }]
}, {
    name: "Maasten Motocenter",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/8/8c/Mixed2_main_circuit.png",
        length: 1050
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/e/e1/Mixed2_main_circuit_rev.png",
        length: 1050
    }]
}, {
    name: "Madman Stadium",
    tracks: [{
        name: "Figure 8",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/a1/Bigstadium_figure_8.png",
        length: 454
    }]
}, {
    name: "Midwest Motocenter",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/e/e8/Gravel1_main_loop.png",
        length: 1330
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/a2/Gravel1_main_loop_rev.png",
        length: 1330
    }]
}, {
    name: "Motorcity Circuit",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/7/7c/Tarmac2_main_circuit.png",
        length: 2940
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/9/95/Tarmac2_main_circuit_rev.png",
        length: 2940
    }, {
        name: "Trophy Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/e/e2/Tarmac2_main_circuit_tourney.png",
        length: 2940
    }]
}, {
    name: "Mudford Motorpark",
    tracks: [{
        name: "Mud Oval",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/7/7b/Fields10_1.png",
        length: 383
    }]
}, {
    name: "Nortfolk Ring",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/b/b0/Mixed8_r1.png",
        length: 1140
    }, {
        name: "Open Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/7/7a/Mixed8_r2.png",
        length: 1110
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/c/c6/Mixed8_r3_rev.png",
        length: 1110
    }]
}, {
    name: "Northland Raceway",
    tracks: [{
        name: "Outer Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/8/89/Mixed5_outer_loop.png",
        length: 2090
    }, {
        name: "Outer Route Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/a0/Mixed5_outer_loop_rev.png",
        length: 2090
    }, {
        name: "Inner Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/d/dc/Mixed5_inner_loop.png",
        length: 2000
    }, {
        name: "Inner Route Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/af/Mixed5_inner_loop_rev.png",
        length: 2000
    }, {
        name: "Free Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/0/09/Mixed5_free_route.png",
        length: 2000
    }]
}, {
    name: "Pinehills Raceway",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/7/7c/Mixed3_long_loop.png",
        length: 1810
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/c/c7/Mixed3_long_loop_rev.png",
        length: 1810
    }, {
        name: "Rally Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/1/13/Mixed3_r3.png",
        length: 2240
    }, {
        name: "Rally Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/9/91/Mixed3_r3_rev.png",
        length: 2240
    }, {
        name: "Short Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/9/9d/Mixed3_short_loop.png",
        length: 1340
    }, {
        name: "Short Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/3/33/Mixed3_short_loop_rev.png",
        length: 1340
    }]
}, {
    name: "Rally Trophy",
    tracks: [{
        name: "Special Stage",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/65/Rt01_1.png",
        length: 11980
    }]
}, {
    name: "Rattlesnake Racepark",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/d/da/Fields14_1.png",
        length: 1670
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/ab/Fields14_2.png",
        length: 1670
    }]
}, {
    name: "Rockfield Roughspot",
    tracks: [{
        name: "Dirt Oval",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/3/3c/Fields09_1.png",
        length: 848
    }]
}, {
    name: "Rosenheim Raceway",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/c/cf/Mixed4_main_circuit.png",
        length: 1390
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/1/18/Mixed4_main_circuit_rev.png",
        length: 1390
    }]
}, {
    name: "Sandstone Raceway",
    tracks: [{
        name: "Main Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/9/93/Sandpit1_long_loop.png",
        length: 2460
    }, {
        name: "Main Route Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/5/51/Sandpit1_long_loop_rev.png",
        length: 2460
    }, {
        name: "Short Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/d/d9/Sandpit1_short_loop.png",
        length: 1600
    }, {
        name: "Short Route Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/0/05/Sandpit1_short_loop_rev.png",
        length: 1600
    }, {
        name: "Alt Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/f/f1/Sandpit1_alt_loop.png",
        length: 2540
    }, {
        name: "Alt Route Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/f/ff/Sandpit1_alt_loop_rev.png",
        length: 2540
    }]
}, {
    name: "Savolax Sandpit",
    tracks: [{
        name: "Main Route",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/8/8a/Sandpit2_full_circuit.png",
        length: 2260
    }, {
        name: "Main Route Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/a/a2/Sandpit2_full_circuit_rev.png",
        length: 2260
    }, {
        name: "Short Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/f/f1/Sandpit2_2.png",
        length: 1080
    }, {
        name: "Short Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/6c/Sandpit2_2_rev.png",
        length: 1080
    }]
}, {
    name: "Torsdalen Circuit",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/0/06/Forest13_1.png",
        length: 873
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/6f/Forest13_1_rev.png",
        length: 873
    }, {
        name: "Short Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/2/2c/Forest13_2.png",
        length: 1030
    }, {
        name: "Short Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/6/6a/Forest13_2_rev.png",
        length: 1030
    }]
}, {
    name: "Tribend Speedway",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/e/eb/Forest12_1.png",
        length: 873
    }, {
        name: "Reverse Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/4/4c/Forest12_1_rev.png",
        length: 876
    }, {
        name: "Wild Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/1/11/Forest12_2.png",
        length: 873
    }]
}, {
    name: "Vale Falls Circuit",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/9/90/Forest11_1.png",
        length: 913
    }, {
        name: "Main Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/7/71/Forest11_1_rev.png",
        length: 939
    }, {
        name: "Short Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/2/22/Forest11_2.png",
        length: 789
    }, {
        name: "Short Circuit Reverse",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/b/b3/Forest11_2_rev.png",
        length: 809
    }]
}, {
    name: "Wrecknado",
    tracks: [{
        name: "Main Circuit",
        thumbnail: "https://static.wikia.nocookie.net/wreckfest/images/4/41/Urban09_1.png",
        length: 778
    }]
}]