import React from 'react';
import PropTypes from "prop-types";
import './Value.css';

export default class Value extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        percentage: PropTypes.number.isRequired,
        updateValue: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
    };

    render() {
        return (
            <div className="valueWrapper">
                <input
                    className="value"
                    type="text"
                    placeholder="Value"
                    value={this.props.value}
                    onChange={(event) => {this.props.updateValue(this.props.index, "value", event.target.value)}}
                />
                <div className="percentageWrapper" style={{backgroundColor: this.props.disabled ? "#cccccc" : "#ffffff"}}>
                    <div className="sign" style={{
                        color: this.props.disabled ? "#545454" : "#000000",
                        display: this.props.disabled ? 'block' : 'none'
                    }}>
                        %
                    </div>
                    <input
                        className="percentage"
                        type="number"
                        min={0}
                        max={99999}
                        value={Math.floor(this.props.percentage * 10) / 10}
                        onChange={(event) => {
                            if (event.target.value === "") {
                                event.target.value = "0";
                            }
                            this.props.updateValue(this.props.index, "percentage", event.target.value)
                        }}
                        disabled={this.props.disabled}
                        style={{width: this.props.disabled ? '25px' : '90%'}}
                    />
                </div>
            </div>
        )
    }
}
