import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, TextField
} from '@material-ui/core/index';
import PropTypes from "prop-types";

export default class NewDriverDialog extends React.Component {
    state = {
        open: false,
        error: null,
    };

    static propTypes = {
        showAlert: PropTypes.bool.isRequired,
        setAlert: PropTypes.func.isRequired,
        promptResponse: PropTypes.func.isRequired,
        currentFile: PropTypes.object.isRequired,
    };

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.open !== props.showAlert) {
            return {
                open: props.showAlert,
            }
        }
        return null
    }

    handleCloseN = () => {
        this.props.promptResponse([false, "", ""]);
        this.props.setAlert(false);
        this.setState({open: false});
    };

    handleCloseY = () => {
        let name = document.getElementById('name').value;
        let abbr = document.getElementById('abbr').value;
        let errorMessage = "";

        name = name.replace(/[^\s]+/g, function(word) {
            return word.replace(/^./, function(first) {
                return first.toUpperCase();
            });
        });

        abbr = abbr.toUpperCase();

        if (name === "") {
            errorMessage = "Please enter a name."
        } else if (abbr === "") {
            errorMessage = "Please enter an abbreviation."
        } else if (abbr.length < 3) {
            errorMessage = "Please enter an abbreviation of 3 letters."
        } else {
            let cf = this.props.currentFile;

            for (let i = 0; i < cf.drivers.length; i++) {
                if (name === cf.drivers[i].name) {
                    errorMessage = "Name already in use!";
                    break;
                }

                if (abbr === cf.drivers[i].abbreviation) {
                    errorMessage = "Abbreviation already in use!";
                    break;
                }
            }
        }

        if (errorMessage !== "") {
            this.setState({
                error: errorMessage
            })
        } else {
            this.props.promptResponse([true, name, abbr]);
            this.props.setAlert(false);
            this.setState({open: false});
        }
    };

    render() {
        return (
            <div style={{display: this.props.showAlert}}>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Add new driver"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" hidden={this.state.error === null} style={{
                            color: '#FF1801',
                            backgroundColor: '#333333',
                            borderRadius: 5,
                            padding: 10,
                            margin: '0px 5px'
                        }}>
                            {this.state.error}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Full Name"
                            type="text"
                            fullWidth
                            inputProps={{maxLength: 20}}
                        />
                        <TextField
                            margin="dense"
                            id="abbr"
                            label="Abbreviation"
                            type="text"
                            fullWidth
                            inputProps={{maxLength: 3}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseN} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleCloseY} color="primary" autoFocus>
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
