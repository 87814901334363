import React, {Component} from 'react';
import {db} from '../../services/firebase';
import PropTypes from "prop-types";

export class NewGame extends Component {
    constructor(props) {
        super(props);

        this.state = {
            players: [],
            inputPlayer: {
                name: "",
                score: "",
            }
        }

        this.handleNewPlayerChange = this.handleNewPlayerChange.bind(this);
        this.addPlayer = this.addPlayer.bind(this);
        this.addNewGame = this.addNewGame.bind(this);
    }

    static propTypes = {
        updateView: PropTypes.func.isRequired,
    }

    handleNewPlayerChange(e) {
        const player = this.state.inputPlayer;

        player[e.target.name] = e.target.value;

        this.setState({
            inputPlayer: player
        })
    }

    addPlayer() {
        let newPlayerList = [...this.state.players];
        const newPlayer = this.state.inputPlayer;

        if (newPlayer.name === "") {
            window.alert("Enter a name for the new player!");
        } else if (newPlayerList.some(e => e.name === newPlayer.name)) {
            window.alert(newPlayer.name + " was already added to the list!");
        } else {
            if (newPlayer.score === "") {
                newPlayer.score = 0;
            }

            newPlayerList.push({
                userName: newPlayer.name,
                score: [newPlayer.score]
            })

            newPlayerList.sort((a, b) => (a.userName < b.userName) ? -1 : 1);

            this.setState({
                players: newPlayerList,
                inputPlayer: {
                    name: "",
                    score: ""
                }
            })
        }
    }

    addNewGame() {
        const dateUpdated = Math.round(new Date().getTime() / 1000).toString();
        const players = [...this.state.players];
        let name = "";

        players.forEach((player, index) => {
            if (index === players.length - 1) {
                name += player.userName;
            } else {
                name += player.userName + " - ";
            }
        })

        try {
            let gameKey = db.ref('games').push().key;
            db.ref('games/' + gameKey).set({
                dateUpdated: dateUpdated,
                name: name,
                players: players
            }).then();
        } catch (error) {
            console.error("Failed to read database.")
        }

        this.props.updateView();
    }

    showPlayers() {
        const players = [...this.state.players];

        if (players.length) {
            return players.map((player, index) => {
                return (
                    <div key={index}>
                        {player.userName}, {player.score}
                    </div>
                )
            });
        }
    }

    render() {
        return (
            <div>
                <div>{this.showPlayers()}</div>
                {!this.state.players.length ? <div>No players yet, add some!</div> : null}
                <hr/>
                <div>
                    <div>
                        <input
                            type={'text'}
                            placeholder={'Name'}
                            value={this.state.inputPlayer.name}
                            name={'name'}
                            onChange={this.handleNewPlayerChange}
                        />
                        <input
                            type={'number'}
                            placeholder={0}
                            value={this.state.inputPlayer.score}
                            name={'score'}
                            onChange={this.handleNewPlayerChange}
                        />
                    </div>
                    <button onClick={this.addPlayer}>Add Player</button>
                    <br/>
                    {this.state.players.length ? <button onClick={this.addNewGame}>Save New Game</button> : null}
                </div>
            </div>
        )
    }
}
