import {text} from "./Text";

export function convertTimeToDate(timeInS) {
    let date = new Date(timeInS * 1000);

    return date.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
        + "-" + (date.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
        + "-" + date.getFullYear()
        + " " + text.at + " " + date.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
        + ":" + date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
}