import React from 'react';
import {
    BottomNavigation as BN,
    BottomNavigationAction
} from "@material-ui/core/index";
import {FolderOpen, Timeline, FormatListNumbered} from '@material-ui/icons/index';
import PropTypes from "prop-types";

export default class BottomNavigation extends React.Component {
    state = {
        value: 0
    };

    static propTypes = {
        setScreen: PropTypes.func.isRequired,
        currentFile: PropTypes.object,
    };

    render() {
        return (
            <BN
                value={this.state.value}
                onChange={(event, newValue) => {
                    this.setState({value: newValue});
                    this.props.setScreen(newValue);
                }}
                showLabels
            >
                <BottomNavigationAction label="File Selector" icon={<FolderOpen/>}/>
                <BottomNavigationAction label="Graph" icon={<Timeline/>}
                                        disabled={this.props.currentFile === null}/>
                <BottomNavigationAction label="Results" icon={<FormatListNumbered/>}
                                        disabled={this.props.currentFile === null}/>
            </BN>
        );
    }
}
