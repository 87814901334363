import React from 'react';
import {
    Button,
    FormControl,
    NativeSelect,
    Typography
} from '@material-ui/core/index'
import PropTypes from "prop-types";

export default class DriverTransfer extends React.Component {
    state = {
        results: [],
        resultsFull: false,
        selectableDrivers: this.props.currentFile.drivers,
    };

    static propTypes = {
        currentFile: PropTypes.object.isRequired,
        setFile: PropTypes.func.isRequired,
        setView: PropTypes.func.isRequired,
    };

    componentDidMount() {
        let initialResults = [];
        let drivers = [];
        let cf = this.props.currentFile;

        for (let i = 0; i < cf.drivers.length; i++) {
            drivers.push(cf.drivers[i]);
        }

        for (let i = 0; i < 2; i++) {
            initialResults.push(["", ""]);
        }

        this.setState({
            selectableDrivers: drivers,
            results: initialResults
        })
    }

    // Final method
    updateResults = () => {
        let cf = this.props.currentFile;
        const results = this.state.results;

        for (let i = 0; i < cf.drivers.length; i++) {
            if (cf.drivers[i].abbreviation === results[0][0]) {
                cf.drivers[i].team = results[1][1] === "null" ? null : results[1][1]
            }
            if (cf.drivers[i].abbreviation === results[1][0]) {
                cf.drivers[i].team = results[0][1] === "null" ? null : results[1][1]
            }
        }

        this.props.setFile(cf);
    };

    handleChange = (event) => {
        let value = JSON.parse(event.target.value);
        let results = this.state.results;
        let selectableDrivers = [];
        let cf = this.props.currentFile;

        // Update Results
        results.splice(value.index, 1, value.driverTeam);

        // Update Selectable Drivers
        for (let i = 0; i < this.props.currentFile.drivers.length; i++) {
            selectableDrivers.push(cf.drivers[i]);
        }

        for (let i = 0; i < results.length; i++) {
            let abbr = results[i][0];
            for (let j = 0; j < selectableDrivers.length; j++) {
                if (selectableDrivers[j].abbreviation === abbr) {
                    selectableDrivers.splice(j, 1);
                }
            }
        }

        this.setState({
            results: results,
            selectableDrivers: selectableDrivers
        });

        this.isResultsFull();
    };

    isResultsFull = () => {
        let results = this.state.results;
        for (let i = 0; i < results.length; i++) {
            if (results[i].toString() === ",") {
                this.setState({
                    resultsFull: false
                });
                return false;
            }
        }
        this.setState({
            resultsFull: true
        });
        return true;
    };

    getFullName = (abbr) => {
        if (abbr === "") {
            return ""
        } else {
            let cf = this.props.currentFile;

            for (let i = 0; i < cf.drivers.length; i++) {
                if (cf.drivers[i].abbreviation === abbr) {
                    return cf.drivers[i].name;
                }
            }
        }
    };

    showTransfer = () => {
        if (this.state.results.length === 0) {
            return(<div/>)
        } else {
            let cf = this.props.currentFile;
            let team1Color = '#ffffff';
            let team2Color = '#ffffff';

            for (let i = 0; i < cf.teams.length; i++) {
                if (cf.teams[i].name === this.state.results[0][1]) {
                    team1Color = cf.teams[i].color;
                }
                if (cf.teams[i].name === this.state.results[1][1]) {
                    team2Color = cf.teams[i].color;
                }
            }

            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1,
                    flexDirection: 'column',
                    margin: 10
                }}>
                    <FormControl variant="filled" style={{flex: 1, marginLeft: 10, marginRight: 10, minWidth: 150}}>
                        <NativeSelect
                            value={this.state.results[0][0]}
                            onChange={this.handleChange}
                        >
                            <option style={{display: 'none'}}
                                    value="">{this.getFullName(this.state.results[0][0])}</option>
                            {this.state.selectableDrivers.map((driver2, index2) => {
                                return (
                                    <option
                                        key={index2}
                                        value={'{"index": ' + 0 + ', "driverTeam": ["' + driver2.abbreviation + '","' + driver2.team + '"] }'}
                                    >
                                        {driver2.name}
                                    </option>
                                )
                            })}
                        </NativeSelect>
                    </FormControl>
                    <Typography variant='body1' style={{
                        opacity: this.state.results[0][1] === "" ? 0 : 100,
                        marginTop: 10,
                        marginBottom: 10,
                    }}>
                        leaves <span style={{
                        textDecoration: 'underline',
                        textDecorationColor: team1Color,
                        // textUnderlinePosition: 'under'
                    }}>
                        {this.state.results[0][1]}
                    </span> and replaces
                    </Typography>
                    <FormControl variant="filled" style={{
                        flex: 1,
                        marginLeft: 10,
                        marginRight: 10,
                        minWidth: 150,
                        opacity: this.state.results[0][1] === "" ? 0 : 100,
                    }}>
                        <NativeSelect
                            value={this.state.results[1][0]}
                            onChange={this.handleChange}
                        >
                            <option style={{display: 'none'}}
                                    value="">{this.getFullName(this.state.results[1][0])}</option>
                            {this.state.selectableDrivers.map((driver2, index2) => {
                                return (
                                    <option
                                        key={index2}
                                        value={'{"index": ' + 1 + ', "driverTeam": ["' + driver2.abbreviation + '","' + driver2.team + '"] }'}
                                    >
                                        {driver2.name}
                                    </option>
                                )
                            })}
                        </NativeSelect>
                    </FormControl>
                    <Typography variant='body1' style={{
                        opacity: this.state.results[1][1] === "" ? 0 : 100,
                        marginTop: 10,
                        marginBottom: 10,
                    }}>
                        at <span style={{
                        textDecoration: 'underline',
                        textDecorationColor: team2Color,
                        // textUnderlinePosition: 'under'
                        // TODO: Something goes wrong here, don't know what...
                    }}>
                        {this.state.results[1][1]}
                    </span> and vice versa.
                    </Typography>
                </div>
            )
        }
    };

    render() {
        return (
            <div style={{
                backgroundColor: '#424242',
                borderRadius: 5,
                marginTop: 20,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
            }}>
                {this.showTransfer()}
                <Button variant='contained' color='primary' disabled={!this.state.resultsFull} onClick={() => {
                    this.updateResults();
                    this.props.setView("table");
                }} style={{
                    margin: 20,
                    marginTop: 10
                }}>
                    Transfer the drivers
                </Button>
            </div>
        )
    }
}
