import React, {Component} from 'react';
import {GameList} from "./GameList";
import {Game} from "./Game";
import {NewGame} from "./NewGame";

export class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: null,
            gameId: null,
        }
    }

    updateView = (view = null, gameId = null) => {
        this.setState({view, gameId});
        window.history.pushState(null, '', document.URL);
    }

    render() {
        switch (this.state.view) {
            case "game":
                return (
                    <Game
                        gameId={this.state.gameId}
                        closeGame={this.updateView}
                    />
                )
            case "newGame":
                return (
                    <NewGame
                        updateView={this.updateView}
                    />
                )
            default:
                return (
                    <GameList
                        updateView={this.updateView}
                    />
                );
        }
    }
}
