import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class AdonisButton extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <button onClick={this.props.onClick} style={{
                padding: 10,
                borderRadius: 5,
                fontSize: 16,
                background: '#42a5f5',
                border: 'none',
                boxShadow: '0px 1px 2px 1px #bbb',
                fontWeight: 'bold',
                color: 'white',
                outline: 'none',
                minWidth: '50%'
            }}>{this.props.text}</button>
        )
    }
}
