import React, {Component} from 'react';
import {auth} from "../../../services/firebase";
import {Home} from './Home';
import {Button, CircularProgress} from "@material-ui/core";

export class Wrapper extends Component {
    state = {
        loading: true,
        loggedIn: null,
    };

    render() {
        if (this.state.loading) {
            auth().onAuthStateChanged((user) => {
                let loggedIn = false;

                if (user) {
                    loggedIn = true;
                }

                this.setState({
                    loading: false,
                    loggedIn: loggedIn,
                })
            });

            return (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '50%',
                }}>
                    <CircularProgress/>
                </div>
            )
        } else {
            if (this.state.loggedIn) {
                return (
                    <Home/>
                );
            } else {
                return (
                    <Button variant="contained" color="primary" onClick={() => console.log(auth().currentUser)}>
                        boop
                    </Button>
                );
            }
        }
    }
}
