import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core/index';
import PropTypes from "prop-types";

export default class AlertDialog extends React.Component {
    state = {
        open: false
    };

    static propTypes = {
        showAlert: PropTypes.bool.isRequired,
        setAlert: PropTypes.func.isRequired,
        promptResponse: PropTypes.func.isRequired,
    };

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.open !== props.showAlert) {
            return {
                open: props.showAlert,
            }
        }
        return null
    }

    handleCloseN = () => {
        this.props.promptResponse(false);
        this.props.setAlert(false);
        this.setState({open: false});
    };

    handleCloseY = () => {
        this.props.promptResponse(true);
        this.props.setAlert(false);
        this.setState({open: false});
    };

    render() {
        return (
            <div style={{display: this.props.showAlert}}>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want a new season?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You still have an active season, save this before creating or importing a new season.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseN} color="primary">
                            No
                        </Button>
                        <Button onClick={this.handleCloseY} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
