import React, {Component} from 'react';
import PropTypes from "prop-types";
import {getCircuitInfo, getCircuitList, getDrivers, getQuestions, storeAnswer} from "../firebase/db";
import Answer from './Answer';

export default class CircuitList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: [],
            drivers: [],
            circuit: {},
            answers: []
        }
    }

    static propTypes = {
        raceId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        gotoPage: PropTypes.func.isRequired
    }

    componentDidMount() {
        getQuestions(this.props.raceId, this.handleQuestionList).then()
        getDrivers(this.handleDriverList).then()
        getCircuitInfo(this.props.raceId, this.handleCircuitInfo).then()
    }

    handleQuestionList = (questions) => {
        console.log(questions)
        this.setState({
            questions: questions
        })

        for (let i = 0; i < questions.length; i++) {
            this.updateAnswer(i, "")
        }
    }

    handleDriverList = (drivers) => {
        console.log(drivers)
        this.setState({
            drivers: drivers
        })
    }

    handleCircuitInfo = (circuit) => {
        console.log(circuit)
        this.setState({
            circuit: circuit
        })
    }

    updateAnswer = (questionNumber, answer) => {
        let answers = this.state.answers;
        answers.splice(questionNumber, 1, answer)
        this.setState({answers: answers})
    }

    handleSubmit = () => {
        const answers = this.state.answers;
        let empty = false;

        answers.forEach((answer) => {
            if (answer === "") {
                empty = true
            }
        })

        if (answers.length < this.state.questions.length || empty) {
            window.alert('Niet alle antwoorden zijn ingevuld!')
        } else {
            storeAnswer(this.props.raceId, this.props.name, answers).then()
            window.alert("Antwoorden succesvol doorgegeven!")
            this.props.gotoPage('home')
        }
    }

    showQuestionList() {
        const questions = this.state.questions;

        if (this.state.drivers.length !== 0 && Object.keys(this.state.circuit).length !== 0) {
            return questions.map((question, index) => {
                return (
                    <Answer
                        key={index}
                        questionNumber={index}
                        question={question[1].question}
                        answerStyle={question[1].answerStyle}
                        drivers={this.state.drivers}
                        lapCount={this.state.circuit.lapCount}
                        updateAnswer={this.updateAnswer}
                    />
                )
            })
        }
    }

    render() {
        if (this.state.questions.length === 0) {
            return <p>Loading...</p>
        } else {
            return (
                <div>
                    <div>{this.showQuestionList()}</div>
                    <div style={{
                       border: '1px solid black'
                    }} onClick={this.handleSubmit}>
                        Antwoorden verzenden
                    </div>
                </div>
            )
        }
    }
}
