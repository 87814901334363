import React from 'react';
import PropTypes from "prop-types";
import './SpotifyLoginButton.css';

const authEndpoint = 'https://accounts.spotify.com/authorize';
const clientId = "989a4c7e2d95456a8933e71c430a48bc";
const host = window.location.host;
const redirectUriPrefix = host === "projects.localhost:3000" ? "http://" : "https://";
const redirectUri = redirectUriPrefix + host + "/bingo/new";

const hash = window.location.hash
    .substring(1)
    .split("&")
    .reduce(function (initial, item) {
        if (item) {
            const parts = item.split("=");
            initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
    }, {});

window.location.hash = "";

export default class SpotifyLogInButton extends React.Component {
    static propTypes = {
        setToken: PropTypes.func.isRequired,
    };

    componentDidMount() {
        let _token = hash.access_token;
        if (_token) {
            this.props.setToken(_token);
        }
    }

    render() {
        return (
            <a
                id="spotifyLoginButton"
                href={`${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&show_dialog=false`}
            >
                LOGIN TO SPOTIFY
            </a>
        )
    }
}
