import React from 'react';
import PropTypes from "prop-types";
import './WinnerPrompt.css';

export default class WinnerPrompt extends React.Component {
    static propTypes = {
        spinnerValue: PropTypes.object,
        setParentState: PropTypes.func.isRequired,
    };

    render() {
        if (this.props.spinnerValue !== undefined) {
            return (
                <div style={{
                    visibility: this.props.spinnerValue !== undefined ? 'visible' : 'hidden',
                }}>
                    <div id="winnerPromptWrapper">
                        <div id="box">
                            <span>You spun:</span>
                            <div id="winnerValue">
                                <span>{this.props.spinnerValue.value}</span>
                            </div>
                            <span>Which had a probability of {Math.floor(this.props.spinnerValue.percentage * 10) / 10}%!</span>
                            <button type={"button"} onClick={() => {
                                this.props.setParentState("spinnerValue", undefined);
                            }}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <div />
        }
    }
}
