import React from "react";

export function F1() {
    const [randomTrack, setRandomTrack] = React.useState(null);

    function generate() {
        let r1 = Math.floor(Math.random() * tracks.length);
        setRandomTrack(tracks[r1]);
    }

    function showGeneratedTrack() {
        if (randomTrack !== null) {
            return (
                <div>
                    <div key={randomTrack.name} style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 20,
                        borderRadius: "5px",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        marginBottom: 16,
                        background: "#fff",
                    }}>
                        <div style={{
                            width: "50px",
                            display: "flex",
                            justifyContent: "center",
                        }}/>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "16px 0"
                        }}>
                            <div>
                                {randomTrack.name}
                            </div>
                            <div style={{
                                fontWeight: 16,
                                fontStyle: "italic"
                            }}>
                                {randomTrack.country}
                            </div>
                        </div>
                    </div>
                    <div style={{borderBottom: "1px solid black", marginBottom: 16}}/>
                </div>
            )
        } else {
            return <div/>
        }
    }

    return (
        <div>
            <div className={"generateButton"} onClick={generate}>
                GENERATE
            </div>
            {showGeneratedTrack()}
            <div>
                {tracks.map((track, index) => {
                    return (
                        <div key={track.name} style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: 20,
                            borderRadius: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                            marginBottom: 16,
                            background: "#fff",
                        }}>
                            <div style={{
                                width: "50px",
                                display: "flex",
                                justifyContent: "center",
                            }}>
                                {index + 1}.
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "16px 0"
                            }}>
                                <div>
                                    {track.name}
                                </div>
                                <div style={{
                                    fontWeight: 16,
                                    fontStyle: "italic"
                                }}>
                                    {track.country}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const tracks = [
    {
        name: "Albert Park",
        country: "Australia",
    },
    {
        name: "Bahrain International Circuit",
        country: "Bahrain",
    },
    {
        name: "Imola",
        country: "Italy",
    },
    {
        name: "Portimao",
        country: "Portugal",
    },
    {
        name: "Shanghai International Circuit",
        country: "China",
    },
    {
        name: "Circuit de Barcelona-Catalunya",
        country: "Spain",
    },
    {
        name: "Circuit de Monaco",
        country: "Monaco",
    },
    {
        name: "Baku City Circuit",
        country: "Azerbaijan",
    },
    {
        name: "Circuit Gilles Vileneuve",
        country: "Canada",
    },
    {
        name: "Circuit Paul Ricard",
        country: "France",
    },
    {
        name: "Red Bull Ring",
        country: "Austria",
    },
    {
        name: "Silverstone",
        country: "Great Britain",
    },
    {
        name: "Hungaroring",
        country: "Hungary",
    },
    {
        name: "Spa-Francorchamps",
        country: "Belgium",
    },
    {
        name: "Zandvoort",
        country: "Netherlands",
    },
    {
        name: "Monza",
        country: "Italy",
    },
    {
        name: "Sochi Autodrom",
        country: "Russia",
    },
    {
        name: "Marina Bay",
        country: "Singapore",
    },
    {
        name: "Suzuka",
        country: "Japan",
    },
    {
        name: "Circuit of the Americas",
        country: "USA",
    },
    {
        name: "Circuit Hermanos Rodriguez",
        country: "Mexico",
    },
    {
        name: "Interlagos",
        country: "Brazil",
    },
    {
        name: "Jeddah Street Circuit",
        country: "Saudi Arabia",
    },
    {
        name: "Yas Marina Circuit",
        country: "UAE",
    },
]