export function createArrow() {
    const c = document.createElement("canvas");
    c.setAttribute("width", "260");
    c.setAttribute("height", "260");
    c.setAttribute("style", "position: absolute; top: 0; left: 0;");
    const ctx = c.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(235, 125);
    ctx.lineTo(255, 115);
    ctx.lineTo(255, 135);
    ctx.lineTo(235, 125);
    ctx.fillStyle = '#ff00ff';
    ctx.fill();
    ctx.fillStyle = '#000000';
    ctx.stroke();

    const wrapper = document.getElementById("arrowWrapper");
    wrapper.appendChild(c);
}

export function createCirclePiece(centerX, centerY, radius, percentage, text) {
    const deg = percentage / 100 * 2;

    const c = document.createElement("canvas");
    c.setAttribute("width", "250");
    c.setAttribute("height", "250");
    c.setAttribute("style", "position: absolute; top: 0; left: 0;");
    const ctx = c.getContext("2d");

    // Draw circle piece
    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, deg * Math.PI);
    ctx.lineTo(centerX, centerY);
    ctx.lineTo(centerX + radius, centerY);
    ctx.fillStyle = '#ff0000';
    ctx.fill();
    ctx.stroke();
    ctx.restore();

    const x = radius * Math.cos(deg * Math.PI);
    const y = radius * Math.sin(deg * Math.PI);

    // Draw dots on circle piece
    ctx.save();
    ctx.beginPath();
    ctx.arc(centerX + radius, centerY, 4, 0, 2 * Math.PI);
    ctx.arc(centerX + x, centerY + y, 4, 0, 2 * Math.PI);
    ctx.fillStyle = '#000000';
    ctx.fill();
    ctx.restore();

    // Draw text
    ctx.save();
    ctx.beginPath();
    ctx.fillStyle = '#ffff00';
    ctx.font = "12px sans-serif";
    ctx.textAlign = 'end';
    ctx.translate(centerX, centerY);
    ctx.rotate(deg / 2 * Math.PI);
    ctx.fillText(text, radius - 10, 4);
    ctx.restore();

    const wrapper = document.getElementById("canvasWrapper");
    wrapper.appendChild(c);

    return c;
}
