import React from 'react';
import './ResultsTable.css';
import {
    Fab
} from "@material-ui/core/index";
import {Add, Create, SwapHoriz} from "@material-ui/icons/index";
import PropTypes from "prop-types";

class ButtonOption extends React.Component {
    static propTypes = {
        icon: PropTypes.object.isRequired,
        text: PropTypes.string.isRequired,
        pos: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div onClick={this.props.onClick} style={{
                position: "absolute",
                display: 'inline-block',
                left: 8,
                top: (-60 * this.props.pos),
            }}>
                <div style={{
                    display: 'inline-block',
                    position: 'absolute',
                    width: 150,
                    right: 50,
                    top: 5,
                    textAlign: 'center',
                    backgroundColor: '#00000088',
                    padding: 5,
                    borderRadius: 5,
                }}>
                    {this.props.text}
                </div>
                <Fab size="small">{this.props.icon}</Fab>
            </div>
        )
    }
}

export default class ResultFAB extends React.Component {
    state = {
        buttonPressed: false
    };

    static propTypes = {
        setView: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div style={{
                position: 'fixed',
                bottom: 80,
                right: 20,
            }}>
                <Fab color="primary" aria-label="add new result" onClick={() => {
                    this.setState({buttonPressed: !this.state.buttonPressed})
                }}>
                    <Add style={{
                        transition: "transform 0.2s",
                        ...(this.state.buttonPressed
                            ? {transform: "rotate(135deg)"}
                            : {transform: "rotate(0deg)"})
                    }}/>
                </Fab>
                {this.state.buttonPressed
                    ?
                    <div>
                        <ButtonOption
                            pos={1}
                            icon={<Create/>}
                            text={"Add new race result"}
                            onClick={() => {
                                this.props.setView("addRace");
                                this.setState({buttonPressed: !this.state.buttonPressed})
                            }}
                        />
                        <ButtonOption
                            pos={2}
                            icon={<SwapHoriz/>}
                            text={"Add drivers transfer"}
                            onClick={() => {
                                this.props.setView("driverTransfer");
                                this.setState({buttonPressed: !this.state.buttonPressed})
                            }}
                        />
                    </div>
                    : <div/>}
            </div>
        )
    };
}
