import React, {Component} from 'react';
import PropTypes from "prop-types";

const currentRace = 1;

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: ""
        }
    }

    static propTypes = {
        gotoPage: PropTypes.func.isRequired
    }

    gotoCircuitList = () => {
        this.props.gotoPage("circuitList")
    }

    onInputChange = (event) => {
        const value = event.target.value;
        this.setState({inputValue: value})
    }

    handleSubmit = (event) => {
        event.preventDefault()

        const value = this.state.inputValue

        if (value !== "") {
            this.props.gotoPage("answering", currentRace, value)
        }
    }

    render() {
        return (
            <div>
                <div style={{
                    border: '1px solid red'
                }}>
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type='text'
                            placeholder='Naam'
                            value={this.state.inputValue}
                            onChange={this.onInputChange}
                        />
                    </form>
                    <div onClick={this.handleSubmit}>Scores invullen</div>
                </div>
                <div style={{
                    border: '1px solid green',
                    marginTop: 50
                }} onClick={this.gotoCircuitList}>
                    Resultaten
                </div>
            </div>
        )
    }
}
