import React, {Fragment} from 'react';
import {
    Button,
    Typography
} from "@material-ui/core/index";
import Grid from "@material-ui/core/Grid/index";
import PropTypes from "prop-types";
import {AlertDialog} from '../index';
import NewSeason from './NewSeason';

export default class FileSelector extends React.Component {
    constructor() {
        super();
        this.btnRef = React.createRef();
    }

    state = {
        activeView: 'main',
        fileName: "",
        showAlert: false,
        fileMethod: null,
    };

    static propTypes = {
        setFile: PropTypes.func.isRequired,
        currentFile: PropTypes.object,
    };

    fileReader;

    onImportButtonClick = () => {
        this.btnRef.current.click();
    };

    handleFileRead = () => {
        let data = JSON.parse(this.fileReader.result);
        this.props.setFile(data);
    };

    onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let file = event.target.files[0];
        this.setState({
            fileName: file.name
        });
        this.fileReader = new FileReader();
        this.fileReader.onloadend = this.handleFileRead;
        this.fileReader.readAsText(file);
    };

    showActiveFile = () => {
        if (this.props.currentFile !== null) {
            return (
                <Grid container alignItems="flex-start" justify="center" direction="row" style={{
                    marginTop: 70,
                    padding: 20,
                    backgroundColor: "#FF180288",
                    borderRadius: 5,
                }}>
                    <Typography variant='body1'>
                        You are currently working in a season.
                    </Typography>
                    <a href="/" id="downloadAnchorElem" style={{display: "none"}}>.</a>
                    <Button variant="contained" color="primary" style={{marginTop: 30}}
                            onClick={() => {
                                let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.props.currentFile));
                                let dlAnchorElem = document.getElementById('downloadAnchorElem');
                                dlAnchorElem.setAttribute("href", dataStr);
                                dlAnchorElem.setAttribute("download", "F1Visualizer.json");
                                dlAnchorElem.click();
                            }}>
                        Save this season
                    </Button>
                </Grid>
            )
        }
    };

    handlePrompt = (bool) => {
        if (bool) {
            if (this.state.fileMethod === "new") {
                this.setView('newSeason');
            } else if (this.state.fileMethod === "import") {
                this.onImportButtonClick();
            }
        }
        this.setState({
            fileMethod: null
        })
    };

    setView = (view) => {
        this.setState({
            activeView: view
        })
    };

    showScreen = () => {
        switch (this.state.activeView) {
            case "main":
                return (
                    <div>
                        <Typography variant='h5'>
                            Formula 1 Results Visualizer
                        </Typography>
                        <Typography variant='body1' style={{marginTop: 10}}>
                            This program can visualize the results of a single Formula 1 season. As of now, there is no
                            online saving implemented, only local saving. Start by creating a new season, or by
                            importing an existing one.
                        </Typography>
                        <AlertDialog
                            showAlert={this.state.showAlert}
                            setAlert={(bool) => this.setState({showAlert: bool})}
                            promptResponse={this.handlePrompt}
                        />
                        <Grid container alignItems="flex-start" justify="center" direction="row"
                              style={{marginTop: 50}}>
                            <Button variant="contained" color="primary" onClick={async () => {
                                if (this.props.currentFile !== null) {
                                    this.setState({
                                        showAlert: true,
                                        fileMethod: "new",
                                    });
                                } else {
                                    this.setView('newSeason');
                                }
                            }}>
                                Start new season
                            </Button>

                            <input id="myInput"
                                   type="file"
                                   accept={".json"}
                                   ref={this.btnRef}
                                   style={{display: 'none'}}
                                   onChange={this.onChangeFile.bind(this)}
                            />
                            <Button variant="contained" color="primary" style={{marginTop: 30}}
                                    onClick={() => {
                                        if (this.props.currentFile !== null) {
                                            this.setState({
                                                showAlert: true,
                                                fileMethod: "import"
                                            });
                                        } else {
                                            this.setState({
                                                promptResponse: false
                                            });
                                            this.onImportButtonClick();
                                        }
                                    }}>
                                Import existing season
                            </Button>
                        </Grid>
                        {this.showActiveFile()}
                    </div>
                );
            case "newSeason":
                return (
                    <Fragment>
                        <NewSeason setFile={this.props.setFile} setView={this.setView}/>
                    </Fragment>
                );
            default:
                return <div/>
        }
    };

    render() {
        return (
            <Fragment>
                {this.showScreen()}
            </Fragment>
        )
    }
}
