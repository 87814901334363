import React from 'react';
import CodeInput from '../components/CodeInput';
import BingoChart from '../components/BingoChart';
import {db} from "../../../../services/firebase";

export default class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: "",
            chart: null,
        }
    }

    showError = () => {
        this.setState({
            code: "",
        });

        alert('Invalid Code');
    };

    getBingoChartFromDB = async (code) => {
        const bingoID = code.substring(0, 3);
        const chartID = code.substring(3, 6);

        try {
            await db.ref('bingo/' + bingoID + '/charts/' + chartID).on("value", (snapshot) => {
                if (!snapshot.exists()) {
                    this.showError();
                } else {
                    this.setState({
                        code: code,
                        chart: snapshot.toJSON()
                    });
                }
            });
        } catch (error) {
            console.error("Failed to read database.")
        }
    };

    render() {
        if (this.state.chart === null) {
            return (
                <div>
                    <div style={{width: 200}}>
                        <CodeInput
                            setCode={this.getBingoChartFromDB}
                        />
                        <button style={{
                            width: '100%',
                            marginTop: 20,
                            borderRadius: 8,
                            border: 'none',
                            fontSize: 24,
                            outline: 'none',
                            height: 56
                        }}>Enter
                        </button>
                    </div>
                    <div style={{
                        position: 'absolute',
                        bottom: 24,
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        fontSize: 16,
                    }}>
                        Want to create your own bingo?<br/>
                        <a href={"https://" + window.location.host + "/bingo/new"} style={{
                            textDecoration: 'none',
                            fontWeight: 'bold',
                            color: '#000000',
                        }}>Click here!</a>
                    </div>
                </div>
            )
        } else {
            return (
                <BingoChart
                    code={this.state.code}
                    chart={this.state.chart}
                />
            )
        }
    }
}
