import React, {Component} from 'react';
import Home from './views/Home';
import CircuitList from "./views/CircuitList";
import AnswerPage from './views/AnswerPage';
import CircuitAnswers from "./views/CircuitAnswers";

export class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: {
                page: null,
                raceId: null,
                name: null
            }
        }
    }

    componentDidMount() {
        document.title = "F1 Spel - JorisP.nl";
    }

    updateView = (page = null, raceId = null, name=null) => {
        this.setState({
            view: {
                page: page,
                raceId: raceId,
                name: name
            }
        });
        window.history.pushState(null, '', document.URL);
    }

    render() {
        switch (this.state.view.page) {
            case "circuitList":
                return (
                    <CircuitList gotoPage={this.updateView} />
                )
            case "circuit":
                return (
                    <CircuitAnswers raceId={this.state.view.raceId} />
                )
            case "answering":
                return (
                    <AnswerPage
                        gotoPage={this.updateView}
                        raceId={this.state.view.raceId}
                        name={this.state.view.name}
                    />
                )
            default:
                return (
                    <Home gotoPage={this.updateView}/>
                )
        }
    }
}
