import React from 'react';
import {BottomNavigation, FileSelector, Graph, Results} from './index'

export class Home extends React.Component {
    state = {
        screen: 0,
        currentFile: null,
    };

    setScreen = (value) => {
        this.setState({screen: value});
    };

    setFile = (file) => {
        this.setState({currentFile: file})
    };

    showScreen = () => {
        switch (this.state.screen) {
            case 0:
                return (
                    <FileSelector
                        setFile={this.setFile}
                        currentFile={this.state.currentFile}
                    />
                );
            case 1:
                return <Graph
                    setFile={this.setFile}
                    currentFile={this.state.currentFile}
                />;
            case 2:
                return (
                    <Results
                        setFile={this.setFile}
                        currentFile={this.state.currentFile}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <div style={{flex: 1}}>
                <div style={{flex: 1, margin: 20, marginBottom: 100}}>
                    {this.showScreen()}
                </div>
                <div style={{
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 999
                }}>
                    <BottomNavigation
                        setScreen={this.setScreen}
                        currentFile={this.state.currentFile}
                    />
                </div>
            </div>
        )
    }
}
