import React from 'react';
import './CodeInput.css';
import PropTypes from "prop-types";
// import {analytics} from "../../../../services/firebase";

export default class CodeInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            style: {},
            styleInput: {},
        }
    }

    static propTypes = {
        setCode: PropTypes.func.isRequired,
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const value = this.state.value;

        if (value.length === 6) {
            // analytics.logEvent('code_submitted');
            this.props.setCode(value);
        }
    };

    onChange = (event) => {
        let value = event.target.value;

        if (value.length > 6) {
            value = value.substring(0, value.length - 1);
        }

        this.setState({value});
    };

    onFocus = () => {
        this.setState({
            style: {
                backgroundColor: '#ffffff',
                color: '#000000',
            },
            styleInput: {
                color: '#000000',
            }
        })
    };

    onBlur = () => {
        this.setState({
            style: {},
            styleInput: {}
        })
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div id="wrapper" style={this.state.style}>
                    <div id="number">
                        #
                    </div>
                    <input
                        id="input"
                        type="number"
                        placeholder="Code"
                        min={0}
                        max={999999}
                        value={this.state.value}
                        style={this.state.styleInput}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                    />
                </div>
            </form>
        )
    }
}
