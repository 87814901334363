import React from 'react';
import './BingoChart.css';
import PropTypes from "prop-types";
import {db} from "../../../../services/firebase";

export default class BingoChart extends React.Component {
    static propTypes = {
        code: PropTypes.string.isRequired,
        chart: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.createChart();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.createChart();
    }

    async setChecked(index, checked) {
        const code = this.props.code;
        const bingoID = code.substring(0, 3);
        const chartID = code.substring(3, 6);

        try {
            await db.ref('bingo/' + bingoID + '/charts/' + chartID + '/elements/' + index).update({
                checked: checked,
            });
        } catch (error) {
            console.error("Failed to write to database.")
        }
    }

    createChart() {
        let tbody = document.getElementById("tbody");
        tbody.innerHTML = '';

        const elements = this.props.chart.elements;
        const chartSize = this.props.chart.size;

        // Create rows
        for (let i = 0; i < chartSize; i++) {
            let tr = document.createElement("tr");

            // Create cells
            for (let j = 0; j < chartSize; j++) {
                let index = chartSize * i + j;

                let elem = elements[index];

                let td = document.createElement('td');

                // Highlight checked cell
                if (elem.checked) {
                    td.className += " checked";
                }

                // Set each cell to equal width
                td.style.width = 100 / chartSize + "%";

                // Add rounding edges to corner cells
                if (i === 0) {
                    if (j === 0) {
                        td.className += " topLeft";
                    } else if (j === chartSize - 1) {
                        td.className += " topRight"
                    }
                } else if (i === chartSize - 1) {
                    if (j === 0) {
                        td.className += " bottomLeft";
                    } else if (j === chartSize - 1) {
                        td.className += " bottomRight"
                    }
                }

                // Set title
                let title = document.createElement('span');
                title.setAttribute("class", "title");
                title.appendChild(document.createTextNode(elem.title));

                // Set description
                let description = document.createElement('span');
                description.setAttribute("class", "description");
                description.appendChild(document.createTextNode(elem.description));

                td.onclick = async () => {
                    await this.setChecked(index, !elem.checked);
                };

                td.appendChild(title);
                td.appendChild(document.createElement('br'));
                td.appendChild(description);

                tr.appendChild(td);
            }
            tbody.appendChild(tr);
        }
    }

    render() {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div id="tableWrapper">
                    <table id="table">
                        <tbody id="tbody">
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
