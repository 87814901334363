import React, {Component} from 'react';
import PropTypes from "prop-types";
import {getCircuitList} from '../firebase/db';

export default class CircuitList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            circuits: []
        }
    }

    static propTypes = {
        gotoPage: PropTypes.func.isRequired
    }

    componentDidMount() {
        getCircuitList(this.handleCircuitList).then()
    }

    handleCircuitList = (circuits) => {
        console.log(circuits)
        this.setState({
            circuits
        })
    }

    showCircuitList() {
        const circuits = this.state.circuits;

        return circuits.map((circuit, index) => {
            return (
                <div key={index} style={{
                    border: '1px solid black'
                }} onClick={() => this.gotoCircuit(circuit[0])}>
                    {circuit[1].location}
                </div>
            )
        })
    }

    gotoCircuit = (circuitId) => {
        this.props.gotoPage("circuit", circuitId)
    }

    render() {
        if (this.state.circuits.length === 0) {
            return <p>Loading...</p>
        } else {
            return (
                <div>{this.showCircuitList()}</div>
            )
        }
    }
}
