import React from 'react';
import PropTypes from "prop-types";
import {db} from '../../../../services/firebase';
import SpotifyWrapper from "../components/Spotify/SpotifyWrapper";

export default class NewBingo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bingoID: null,
            chartID: null,
        }
    }

    static propTypes = {
        newBackgroundColor: PropTypes.func.isRequired,
    };

    static pad(num, size) {
        return ('000000000' + num).substr(-size);
    }

    async generateBingoChart(tracks, chartSize) {
        if (this.state.bingoID === null) {
            await this.createNewBingoProject();
        }

        await this.addNewChartToBingoProject(this.state.bingoID, chartSize, tracks);
    }

    generateRandomCharts = async () => {
        let songs = [];
        let chartSize = 5;

        for (let i = 0; i < chartSize * chartSize; i++) {
            songs.push({
                title: 'Song ' + i,
                description: 'Artist ' + i,
                checked: false,
            })
        }

        await this.generateBingoChart(songs, chartSize);
    };

    async createNewBingoProject() {
        const bingoID = await this.getBingoID();

        await this.setState({
            bingoID: bingoID,
        });

        try {
            await db.ref('bingo/' + bingoID).set({
                id: bingoID,
                charts: []
            });
        } catch (error) {
            console.error("Failed to write to database.")
        }
    }

    async getBingoID() {
        let exists = true;
        let randID = null;

        while (exists) {
            exists = false;

            randID = NewBingo.pad(Math.floor(Math.random() * 1000), 3);

            try {
                let tempBool = false;
                let randIDCopy = randID;
                await db.ref('bingo').on("value", (snapshot) => {
                    tempBool = snapshot.hasChild(randIDCopy);
                });
                exists = tempBool;
            } catch (error) {
                console.error("Failed to read database.")
            }
        }

        return randID;
    }

    async getChartID(bingoID) {
        let exists = true;
        let randID = null;

        while (exists) {
            exists = false;

            randID = NewBingo.pad(Math.floor(Math.random() * 1000), 3);

            try {
                let tempBool = false;
                let randIDCopy = randID;
                await db.ref('bingo/' + bingoID + '/charts/').once("value", (snapshot) => {
                    tempBool = snapshot.hasChild(randIDCopy);
                });
                exists = tempBool;
            } catch (error) {
                console.error("Failed to read database.")
            }
        }

        return randID;
    }

    async addNewChartToBingoProject(bingoID, chartSize, chartElements) {
        if (bingoID !== null) {
            const chartID = await this.getChartID(bingoID);

            this.setState({
                chartID: chartID
            });

            try {
                await db.ref('bingo/' + bingoID + '/charts/' + chartID).set({
                    id: chartID,
                    size: chartSize,
                    elements: chartElements,
                });
            } catch (error) {
                console.error("Failed to write to database.")
            }
        }
    }

    render() {
        return (
            <div>
                {/*<button onClick={this.generateRandomCharts}>Generate Random Charts</button>*/}
                <SpotifyWrapper
                    generateChart={async (data) => {
                        await this.generateBingoChart(data.tracks, data.chartSize)
                    }}
                    code={this.state.bingoID + "" + this.state.chartID}
                    newBackgroundColor={this.props.newBackgroundColor}
                />
            </div>
        )
    }
}
