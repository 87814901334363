import React, {Component} from 'react';
import PropTypes from "prop-types";
import AdonisButton from "./AdonisButton";
import {db} from "../../../services/firebase";
import {text} from "../../../Text";

export default class AddScoreDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputScores: []
        }
    }

    static propTypes = {
        toggleDialog: PropTypes.func.isRequired,
        gameId: PropTypes.string.isRequired,
        players: PropTypes.array.isRequired,
    }

    componentDidMount() {
        const players = this.props.players;
        let inputScores = []

        for (let i = 0; i < players.length; i++) {
            inputScores.push("")
        }

        this.setState({
            inputScores: inputScores
        })
    }

    toggleDialog = (event) => {
        if (event.target.id === 'dialogOuterDiv') {
            this.props.toggleDialog()
        }
    }

    addScoresToDB = () => {
        const players = this.props.players;
        const inputScores = this.state.inputScores;
        let valid = true;

        inputScores.forEach((score) => {
            if (parseInt(score) < 0) {
                valid = false
            }
        })

        if (valid) {
            players.forEach((player, index) => {
                let scores = player.score;
                let newScore = inputScores[index]

                if (newScore === "") {
                    newScore = 0
                }

                scores.unshift(parseInt(newScore) + scores[0])

                try {
                    db.ref('games/' + this.props.gameId + '/players/' + index).update({
                        score: scores,
                    }).then();
                } catch (error) {
                    console.error("Failed to write score to database.")
                }
            })

            const dateUpdated = Math.round(new Date().getTime() / 1000).toString();

            try {
                db.ref('games/' + this.props.gameId).update({
                    dateUpdated: dateUpdated,
                }).then();
            } catch (error) {
                console.error("Failed to update date in database.")
            }

            this.props.toggleDialog();
        }
    }

    createPlayerInputs() {
        const updateScore = (index, score) => {
            let inputScores = this.state.inputScores;

            inputScores.splice(index, 1, score)

            this.setState({
                inputScores: inputScores
            })
        }

        if (this.state.inputScores.length > 0) {

            const players = this.props.players;

            return players.map((player, index) => {
                let score = this.state.inputScores[index]

                return <PlayerInput key={index} index={index} playerName={player.userName} score={score}
                                    updateScore={updateScore}/>
            })
        }
    }

    render() {
        return (
            <div id="dialogOuterDiv" onClick={this.toggleDialog} style={{
                background: '#00000088',
                position: 'fixed',
                top: 0,
                left: 0,
                width: window.innerWidth,
                height: window.innerHeight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{
                    width: '80%',
                    background: '#ffffff',
                    borderRadius: 5,
                    boxShadow: '0px 1px 2px 1px #666',
                    padding: 15,
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <span style={{fontWeight: 'bold'}}>{text.enter_scores}</span>
                    <form onSubmit={this.addScoresToDB} style={{
                        display: 'inline',
                        margin: '10px 0'
                    }}>
                        {this.createPlayerInputs()}
                    </form>
                    <AdonisButton text={text.add_score} onClick={this.addScoresToDB}/>
                </div>
            </div>
        )
    }
}

class PlayerInput extends Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        playerName: PropTypes.string.isRequired,
        score: PropTypes.string.isRequired,
        updateScore: PropTypes.func.isRequired,
    }

    updateScore = (event) => {
        this.props.updateScore(this.props.index, event.target.value)
    }

    render() {
        return (
            <div style={{
                display: 'inline-block',
                width: '100%',
                padding: '5px 0px',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <span>{this.props.playerName}</span>
                    <input
                        type='number'
                        min={0}
                        placeholder="0"
                        defaultValue={this.props.score}
                        onChange={this.updateScore}
                        onFocus={(event) => {
                            event.target.style.border = '1px solid #444'
                        }} onBlur={(event) => {
                        event.target.style.border = '1px solid #bbb'
                    }} style={{
                        width: 50,
                        borderRadius: 5,
                        textAlign: 'right',
                        border: '1px solid #bbb',
                        padding: 5,
                        outline: 'none',
                        fontSize: 16
                    }}/>
                </div>
            </div>
        )
    }
}