import React from 'react';
import './ResultsTable.css';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core/index";
import PropTypes from "prop-types";

export default class ResultsTableTeams extends React.Component {
    constructor(props) {
        super(props);
        this.table = React.createRef();
    }

    state = {
        paperWidth: 100
    };

    static propTypes = {
        currentFile: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.setState({
            paperWidth: this.table.current.clientWidth
        })
    }

    static sortTeams(teams) {
        let swap;
        let n = teams.length - 1;
        let x = teams;
        let circuitIndex = teams[0].points.length - 1;

        do {
            swap = false;

            for (let i = 0; i < n; i++) {
                if (x[i].points[circuitIndex] < x[i + 1].points[circuitIndex]) {
                    let temp = x[i];
                    x[i] = x[i + 1];
                    x[i + 1] = temp;
                    swap = true;
                }
            }

            n--;
        } while (swap);

        return x;
    }

    calcTeamsResults() {
        let file = this.props.currentFile;
        let index = 0;

        ResultsTableTeams.sortTeams(file.teams);

        return file.teams.map((team) => {
            index++;
            return (
                <TableRow key={team.name}>
                    <TableCell component="th" scope="row" style={{
                        flex: 1,
                        flexDirection: 'row',
                        padding: 0,
                    }}>
                        <div style={{
                            display: 'inline-block',
                            position: 'relative',
                            top: 2,
                            width: 30,
                            height: '100%',
                            textAlign: 'end',
                            marginRight: 5,
                        }}>
                            <span style={{
                                fontSize: 16,
                            }}>
                                {index}.
                            </span>
                        </div>
                        <div style={{
                            display: 'inline-block',
                            width: 10,
                            height: '100%',
                            verticalAlign: 'middle'
                        }}>
                            <div style={{
                                width: 5,
                                height: 20,
                                borderRadius: 5,
                                backgroundColor: team.color
                            }}/>
                        </div>
                        <div style={{
                            display: 'inline-block',
                            width: 140,
                            height: '100%',
                        }}>
                            <div style={{
                                verticalAlign: "middle",
                            }}>
                            <span style={{
                                verticalAlign: "middle",
                            }}>
                                {team.name}
                            </span>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell key={index} align="center">
                        <span>
                            {team.points[team.points.length - 1]}
                        </span>
                    </TableCell>
                </TableRow>
            );
        });
    }

    render() {
        return (
            <div className="tableWrapper">
                <Paper style={{width: this.state.paperWidth}}>
                    <Table ref={this.table} size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Teams</TableCell>
                                <TableCell>Points</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.calcTeamsResults()}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    };
}
