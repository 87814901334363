import React from 'react';
import PropTypes from "prop-types";
import './Options.css';
import Value from '../Value/Value';

export default class Options extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: [],
            originalNumbers: [60, 40],
            equalChance: false,
            spinWheel: false,
        }
    }

    static propTypes = {
        values: PropTypes.array.isRequired,
        nrOfPieces: PropTypes.number.isRequired,
        setParentState: PropTypes.func.isRequired,
    };

    componentDidMount() {
        let values = [{
            value: 'Red Balls',
            percentage: 3 / 6 * 100,
        }, {
            value: 'Green Balls',
            percentage: 2 / 6 * 100,
        }, {
            value: 'Blue Balls',
            percentage: 1 / 6 * 100,
        }];

        this.setState({
            values: values,
            originalNumbers: [3, 2, 1]
        });

        this.props.setParentState("values", values);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.values) !== JSON.stringify(this.state.values)) {
            this.props.setParentState("values", this.state.values);
        }
    }

    equalChance = (checked) => {
        let values = [];

        for (let i = 0; i < this.state.values.length; i++) {
            values.push({
                value: this.state.values[i].value,
                percentage: this.state.values[i].percentage
            })
        }

        this.setState({
            equalChance: checked
        });

        if (checked) {
            const percentages = [];
            const equalPercentage = 100 / values.length;

            for (let i = 0; i < values.length; i++) {
                percentages.push(this.state.originalNumbers[i]);
                values[i].percentage = equalPercentage;
            }

            this.setState({
                originalNumbers: percentages,
                values: values,
            });
        } else {
            const originalNumbers = this.state.originalNumbers;

            for (let i = 0; i < originalNumbers.length; i++) {
                const total = originalNumbers.reduce((a, b) => a + b, 0);

                values[i].percentage = originalNumbers[i] / total * 100;
            }

            this.setState({
                values: values,
            })
        }
    };

    updateValue = (index, type, value) => {
        const values = this.state.values;
        const originalNumbers = this.state.originalNumbers;

        if (type === "percentage") {
            originalNumbers[index] = parseInt(value);

            for (let i = 0; i < originalNumbers.length; i++) {
                const total = originalNumbers.reduce((a, b) => a + b, 0);

                values[i][type] = originalNumbers[i] / total * 100;
            }
        } else {
            values[index][type] = value;
        }

        this.setState({
            originalNumbers: originalNumbers,
            values: values,
        });
    };

    showValues = () => {
        const values = this.state.values;

        if (values !== null) {
            return values.map((value, index) => {
                return (
                    <Value
                        key={index}
                        index={index}
                        value={value.value}
                        percentage={!this.state.equalChance ? this.state.originalNumbers[index] : this.state.values[index].percentage}
                        updateValue={this.updateValue}
                        disabled={this.state.equalChance}
                    />
                )
            })
        } else {
            return (<p>No values!</p>)
        }
    };

    handleValueCount = async (type) => {
        let values = [];

        for (let i = 0; i < this.state.values.length; i++) {
            values[i] = this.state.values[i]
        }

        const originalNumbers = this.state.originalNumbers;

        if (type === "add") {
            values.push({
                value: "",
                percentage: 0,
            });

            originalNumbers.push(0);
        } else {
            values.pop();
            originalNumbers.pop();
        }

        await this.setState({
            values: values,
            originalNumbers: originalNumbers
        });

        this.equalChance(this.state.equalChance)
    };

    render() {
        return (
            <div id="optionsWrapper">
                <div id="options">
                    <div id="equalChance">
                        <span>Every value has equal chance:</span>
                        <input type="checkbox" checked={this.state.equalChance}
                               onChange={(event) => this.equalChance(event.target.checked)}/>
                    </div>
                    <div id="nrOfPieces" style={{marginTop: 8, position: 'relative'}}>
                        <div style={{maxWidth: '80%'}}>Number of pieces for each value:</div>
                        <input
                            type="number"
                            value={this.props.nrOfPieces}
                            min={1}
                            max={10}
                            onChange={(event) => {
                                this.props.setParentState("nrOfPieces", parseInt(event.target.value))
                            }}
                        />
                    </div>
                </div>
                <hr/>
                <div id="values">
                    {this.showValues()}
                    <div id="count" style={{marginTop: 16}}>
                        <button id="delete" type="button" onClick={() => this.handleValueCount(("delete"))}>Delete
                            Previous
                        </button>
                        <button id="add" type="button" onClick={() => this.handleValueCount(("add"))}>Add New</button>
                    </div>
                </div>
            </div>
        )
    }
}
