import React, {Component} from 'react';
import {db} from '../../services/firebase';
import GameListElement from "./components/GameListElement";
import PropTypes from "prop-types";
import AdonisButton from "./components/AdonisButton";
import Loader from "./components/Loader";
import {text} from '../../Text';

export class GameList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            games: []
        }
    }

    static propTypes = {
        updateView: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.getScoreListsFromDB().then();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
        };
    }

    getScoreListsFromDB = async () => {
        try {
            await db.ref('games').on("value", (snapshot) => {
                if (!snapshot.exists()) {
                    console.log("error");
                } else {
                    let gamesFromDB = [];

                    snapshot.forEach(function (childSnapshot) {
                        gamesFromDB.push([childSnapshot.key, childSnapshot.val()]);
                    })

                    this.setState({
                        games: gamesFromDB
                    })
                }
            })
        } catch (error) {
            console.error("Failed to read database.")
        }
    }

    gotoGame = (gameId) => {
        this.props.updateView("game", gameId);
    }

    gotoNewGame = () => {
        this.props.updateView("newGame");
    }

    showScores() {
        const games = this.state.games;

        games.sort((a, b) => (a[1].dateUpdated < b[1].dateUpdated) ? 1 : -1);

        if (games.length) {
            return games.map((game, index) => {
                return (
                    <div key={index}>
                        <GameListElement
                            game={game}
                            gotoGame={this.gotoGame}
                        />
                    </div>
                )
            });
        }
    }

    render() {
        if (this.state.games.length) {
            return (
                <div>
                    <div style={{
                        marginTop: 20
                    }}>{this.showScores()}</div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 30
                    }}>
                        <AdonisButton text={text.new_game} onClick={this.gotoNewGame}/>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '100px'
                }}>
                    <Loader/>
                </div>
            )
        }
    }
}
