import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Home from './components/home/Home'
import {App as BingoApp} from "../projects/bingo/App";
import {App as F1App} from "../projects/f1/App";
import {App as SpinnerApp} from "../projects/spinner/App";
import {App as AdonisApp} from "../projects/adonis/App";
import {App as F1SpelApp} from '../projects/f1spel/App';
import {App as TracksApp} from "../projects/tracks/App";

export class App extends Component {
    // Main render method
    render() {
        const projectsPath = '/projects';

        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    {/* Projects */}
                    <Route path={projectsPath + "/bingo"}>
                        <BingoApp/>
                    </Route>
                    <Route path={projectsPath + "/f1"}>
                        <F1App/>
                    </Route>
                    <Route path={projectsPath + "/spinner"}>
                        <SpinnerApp/>
                    </Route>
                    <Route path={projectsPath + "/adonis"}>
                        <AdonisApp/>
                    </Route>
                    <Route path={projectsPath + "/f1spel"}>
                        <F1SpelApp/>
                    </Route>
                    <Route path={projectsPath + "/tracks"}>
                        <TracksApp />
                    </Route>
                    <Route path="*">
                        Whoops, that address does not exist!
                    </Route>
                </Switch>
            </Router>
        )
    }
}
