import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBYJgue-jqosIWv0dqtwHmOfFF2bONcN_0",
    authDomain: "f1spel.firebaseapp.com",
    projectId: "f1spel",
    storageBucket: "f1spel.appspot.com",
    messagingSenderId: "774944079809",
    appId: "1:774944079809:web:4255782188ca24f7c8f940"
};

const f1SpelFirebaseApp = firebase.initializeApp(firebaseConfig, 'F1Spel');

export const db = f1SpelFirebaseApp.database();