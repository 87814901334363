import React from 'react';
import './ResultsTable.css';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core/index";
import PropTypes from "prop-types";

export default class ResultsTableDrivers extends React.Component {
    constructor(props) {
        super(props);
        this.table = React.createRef();
    }

    state = {
        paperWidth: 100
    };

    static propTypes = {
        currentFile: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.setState({
            paperWidth: this.table.current.clientWidth
        })
    }

    createCircuitsHeader() {
        let file = this.props.currentFile;

        return file.results.map((race, index) => {
            return <TableCell align="center" key={index}>{race.countryAbbreviation}</TableCell>
        })
    }

    calcDriverResults(abbr) {
        let file = this.props.currentFile;
        let scores = [];

        file.results.map((race, index) => {
            let raceResults = race.results;

            for (let i = 0; i < raceResults.length; i++) {
                if (raceResults[i][0] === abbr) {
                    scores.push(i + 1);
                    break;
                }
            }

            if (scores[index] === undefined) {
                scores.push("");
            }

            return true;
        });

        return scores;
    }

    teamColor(teamName) {
        let file = this.props.currentFile;

        for (let i = 0; i < file.teams.length; i++) {
            let team = file.teams[i];

            if (team.name === teamName) {
                return team.color;
            }
        }
    }

    static sortDrivers(drivers) {
        let swap;
        let n = drivers.length - 1;
        let x = drivers;
        let circuitIndex = drivers[0].points.length - 1;

        do {
            swap = false;

            for (let i = 0; i < n; i++) {
                if (x[i].points[circuitIndex] < x[i + 1].points[circuitIndex]) {
                    let temp = x[i];
                    x[i] = x[i + 1];
                    x[i + 1] = temp;
                    swap = true;
                }
            }

            n--;
        } while (swap);

        return x;
    }

    calcDriversResults() {
        let file = this.props.currentFile;
        let index = 0;

        ResultsTableDrivers.sortDrivers(file.drivers);

        return file.drivers.map((driver) => {
            let driverResults = this.calcDriverResults(driver.abbreviation);

            if (!(driver.team === null && driver.points[driver.points.length - 1] === 0)) {
                index++;
                return (
                    <TableRow key={driver.abbreviation}>
                        <TableCell component="th" scope="row" style={{
                            flex: 1,
                            flexDirection: 'row',
                            padding: 0,
                        }}>
                            <div style={{
                                display: 'inline-block',
                                position: 'relative',
                                top: 2,
                                width: 30,
                                height: '100%',
                                textAlign: 'end',
                                marginRight: 5,
                            }}>
                            <span style={{
                                fontSize: 16,
                            }}>
                                {index}.
                            </span>
                            </div>
                            <div style={{
                                display: 'inline-block',
                                width: 10,
                                height: '100%',
                                verticalAlign: 'middle'
                            }}>
                                <div style={{
                                    width: 5,
                                    height: 20,
                                    borderRadius: 5,
                                    backgroundColor: this.teamColor(driver.team)
                                }}/>
                            </div>
                            <div style={{
                                display: 'inline-block',
                                width: 40,
                                height: '100%',
                            }}>
                                <div style={{
                                    verticalAlign: "middle",
                                }}>
                            <span style={{
                                verticalAlign: "middle",
                            }}>
                                {driver.abbreviation}
                            </span>
                                </div>
                            </div>
                            <div style={{
                                display: 'inline-block',
                                width: 30,
                                height: '100%',
                                textAlign: 'center',
                                backgroundColor: '#555555',
                                borderRadius: 5,
                                margin: 5,
                                marginRight: 10,
                            }}>
                            <span style={{
                                fontSize: 12,
                                fontStyle: 'italic',
                            }}>
                                {driver.points[driver.points.length - 1]}
                            </span>
                            </div>
                        </TableCell>
                        {driverResults.map((result, index) => {
                            let fastestLapStyle = {
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'inline-block',
                                width: 22,
                                padding: 2,
                            };

                            if (file.results[index].fastestLap === driver.abbreviation) {
                                fastestLapStyle['backgroundColor'] = '#ad3add';
                                fastestLapStyle['borderRadius'] = 5;
                            }

                            return (
                                <TableCell key={index} align="center">
                                <span style={fastestLapStyle}>
                                    {result}
                                </span>
                                </TableCell>
                            )
                        })}
                    </TableRow>
                );
            } else {
                return <TableRow key={driver.abbreviation}/>
            }
        });
    }

    render() {
        return (
            <div className="tableWrapper">
                <Paper style={{width: this.state.paperWidth}}>
                    <Table ref={this.table} size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Drivers</TableCell>
                                {this.createCircuitsHeader()}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.calcDriversResults()}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    };
}
