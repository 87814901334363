import {db} from './firebase'

export async function getCircuitList(callback) {
    try {
        await db.ref('circuits').on('value', (snapshot) => {
            if (!snapshot.exists()) {
                console.error('No circuits exist on database!')
            } else {
                let circuitList = []

                snapshot.forEach(function (childSnapshot) {
                    circuitList.push([childSnapshot.key, childSnapshot.val()])
                })

                callback(circuitList)
            }
        })
    } catch (error) {
        console.error('Failed to retrieve circuits from database!')
    }
}

export async function getQuestions(raceId, callback) {
    try {
        await db.ref('circuits/' + raceId + '/questions').on('value', (snapshot) => {
            if (!snapshot.exists()) {
                console.error('No questions exist for this circuit on the database!')
            } else {
                let questionList = []

                snapshot.forEach(function (childSnapshot) {
                    questionList.push([childSnapshot.key, childSnapshot.val()])
                })

                callback(questionList)
            }
        })
    } catch (error) {
        console.error('Failed to retrieve questions from database!')
    }
}

export async function getDrivers(callback) {
    try {
        await db.ref('drivers').on('value', (snapshot) => {
            if (!snapshot.exists()) {
                console.error('No drivers exist on the database!')
            } else {
                let driverList = []

                snapshot.forEach(function (childSnapshot) {
                    driverList.push(childSnapshot.val())
                })

                callback(driverList)
            }
        })
    } catch (error) {
        console.error('Failed to retrieve drivers from database!')
    }
}

export async function getCircuitInfo(raceId, callback) {
    try {
        await db.ref('circuits/' + raceId).on('value', (snapshot) => {
            if (!snapshot.exists()) {
                console.error('Circuit does not exist on the database!')
            } else {
                callback(snapshot.val())
            }
        })
    } catch (error) {
        console.error('Failed to retrieve circuit from database!')
    }
}

export async function storeAnswer(raceId, name, answers) {
    try {
        await db.ref('answers/' + raceId + "/" + name).set({
            answers: answers
        });
    } catch (error) {
        console.error("Failed to write to database.")
    }
}

export async function getAnswers(raceId, callback) {
    try {
        await db.ref('answers/' + raceId).on('value', (snapshot) => {
            if (!snapshot.exists()) {
                console.error('Answers do not exist on the database!')
            } else {
                let answerList = []

                snapshot.forEach(function (childSnapshot) {
                    answerList.push([childSnapshot.key, childSnapshot.val().answers])
                })

                callback(answerList)
            }
        })
    } catch (error) {
        console.error('Failed to retrieve answers from database!')
    }
}