import React, {useEffect} from 'react';
import "./App.css";
import {F1} from "./F1";
import {Wreckfest} from "./Wreckfest";

export function App() {
    const [displayPage, setDisplayPage] = React.useState("");

    useEffect(() => {
        document.title = "Tracks - JorisP.nl";
        document.body.style.backgroundColor = "#eee";
    })

    function showPage() {
        switch(displayPage) {
            case "f1": {
                return <F1/>
            }
            case "wreckfest": {
                return <Wreckfest/>
            }
            default: {
                return (
                    <div>
                        <div className={"gameButton"} onClick={() => setDisplayPage("f1")}>
                            <img src={"https://upload.wikimedia.org/wikipedia/commons/f/f2/New_era_F1_logo.png"} style={{
                                width: "80%"
                            }}/>
                        </div>
                        <div className={"gameButton"} onClick={() => setDisplayPage("wreckfest")}>
                            <img src={"https://upload.wikimedia.org/wikipedia/commons/9/9d/Wreckfest-logo.png"} style={{
                                width: "80%"
                            }}/>
                        </div>
                    </div>
                )
            }
        }
    }

    return (
        <div style={{
            fontFamily: 'Roboto',
            padding: 12,
            margin: 0,
            display: "flex",
            flexDirection: "column",
        }}>
            {showPage()}
        </div>
    )

}
