import * as $ from "jquery";

export async function getPlaylistItems(token, playlistId) {
    let tracks = [];
    let offset = 0;
    let gotAllTracks = false;

    while (!gotAllTracks) {
        gotAllTracks = true;
        const data = await getPlaylistItemsFromSpotify(token, playlistId, offset);

        data.items.forEach((track) => {
            tracks.push(track);
        });

        offset += 100;

        if (offset <= data.total) {
            gotAllTracks = false;
        }
    }

    return tracks
}

function getPlaylistItemsFromSpotify(token, playlistId, offset) {
    return $.ajax({
        url: "https://api.spotify.com/v1/playlists/" + playlistId + "/tracks?offset=" + offset,
        type: "GET",
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token);
        },
        success: (data) => {
            return (data);
        }
    });
}
