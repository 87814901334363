import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";
import Main from './pages/Main';
import NewBingo from './pages/NewBingo';
import {createMuiTheme, CssBaseline, MuiThemeProvider} from "@material-ui/core";

export class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            muiTheme: createMuiTheme({
                palette: {
                    primary: {
                        main: '#FF1801'
                    }
                }
            })
        };
    }

    componentDidMount() {
        document.body.style.margin = "0";
        document.title = "Bingo - JorisP.nl";

        this.newBackgroundColor();
    }

    newBackgroundColor = () => {
        const backgroundColors = [
            '#ff8f00', '#558b2f', '#d32f2f', '#d81b60', '#3949ab', '#fbc02d', '#455a64', '#212121'
        ];

        document.body.style.backgroundColor = backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
    }

    render() {
        return (
            <MuiThemeProvider theme={this.state.muiTheme}>
                <CssBaseline/>
                <Switch>
                    <Route exact path="/bingo/">
                        <div style={{
                            height: window.innerHeight,
                            width: window.innerWidth,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Main/>
                        </div>
                    </Route>
                    <Route path="/bingo/new">
                        <div style={{
                            height: window.innerHeight,
                            width: window.innerWidth,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <NewBingo
                                newBackgroundColor={this.newBackgroundColor}
                            />
                        </div>
                    </Route>
                </Switch>
            </MuiThemeProvider>
        )
    }
}
