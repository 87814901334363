import React, {Component} from 'react';
import PropTypes from "prop-types";
import {convertTimeToDate} from "../../../Utils";
import {text} from "../../../Text";

export default class GameListElement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            key: this.props.game[0],
            values: this.props.game[1],
        }
    }

    static propTypes = {
        game: PropTypes.array.isRequired,
        gotoGame: PropTypes.func.isRequired,
    };

    gotoGame = () => {
        this.props.gotoGame(this.state.key);
    }

    getGameNameWithTrophy(game) {
        const players = game[1].players;
        let minScore = 999999;
        let minIndex = 0;

        players.forEach((player, index) => {
            if (player.score[0] < minScore) {
                minScore = player.score[0];
                minIndex = index;
            }
        })

        let gameName = ""

        players.forEach((player, index) => {
            gameName += player.userName

            if (index === minIndex) {
                gameName += " 🏆"
            }

            if (index !== players.length - 1) {
                gameName += " - "
            }
        })

        return gameName
        // return players[minIndex].userName
    }

    render() {
        return (
            <div style={{
                marginBottom: 20,
                padding: 10,
                borderRadius: 5,
                boxShadow: '0px 1px 2px 1px #bbb'
            }} onClick={this.gotoGame}>
                <div><b>{this.getGameNameWithTrophy(this.props.game)}</b></div>
                <div>{text.last_updated + ": " + convertTimeToDate(parseInt(this.state.values.dateUpdated))}</div>
            </div>
        );
    }
}