import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class Podium extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playersOrdered: []
        }
    }

    static propTypes = {
        series: PropTypes.array.isRequired
    }

    componentDidMount() {
        const series = [...this.props.series];

        series.sort((a, b) => (a.data[a.data.length - 1] > b.data[b.data.length - 1]) ? 1 : -1)

        this.setState({
            playersOrdered: series
        })
    }

    render() {
        if (this.state.playersOrdered.length > 0) {
            return (
                <div style={{
                    borderRadius: 5,
                    boxShadow: '0px 1px 2px 1px #bbb',
                    width: '100%',
                    display: 'flex',
                    paddingTop: 20
                }}>
                    <Stand
                        name={this.state.playersOrdered[1].name}
                        position={2}
                    />
                    <Stand
                        name={this.state.playersOrdered[0].name}
                        position={1}
                    />
                    <Stand
                        name={this.state.playersOrdered[2].name}
                        position={3}
                    />
                </div>
            )
        } else {
            return <div/>
        }
    }
}

class Stand extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
    }

    render() {
        let podiumStyle = {
            width: '100%',
            marginBottom: 'auto',
            height: 20 * (4 - this.props.position) + 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 24,
        }

        let individualStyle = {}
        let icon = ""

        switch (this.props.position) {
            case 1:
                individualStyle = {
                    background: '#c9b037',
                }
                icon = "🥇";
                break;
            case 2:
                individualStyle = {
                    background: '#d7d7d7',
                    borderRadius: '0 0 0 5px'
                }
                icon = "🥈";
                break;
            case 3:
                individualStyle = {
                    background: '#ad8a56',
                    borderRadius: '0 0 5px 0'
                }
                icon = "🥉";
                break;
        }

        podiumStyle = {...podiumStyle, ...individualStyle}

        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                textAlign: 'center'
            }}>
                <div>
                    <span style={{
                        fontWeight: 'bold',
                        fontSize: 18
                    }}>{this.props.name}</span>
                    <div style={podiumStyle}>{icon}</div>
                </div>
            </div>
        );
    }
}