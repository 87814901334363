import React from 'react';
import SpotifyLogInButton from "./SpotifyLogInButton";
import SpotifyPlaylistFetcher from "./SpotifyPlaylistFetcher";
import PropTypes from "prop-types";

export default class SpotifyWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: null,
        }
    }

    static propTypes = {
        generateChart: PropTypes.func.isRequired,
        code: PropTypes.string.isRequired,
        newBackgroundColor: PropTypes.func.isRequired,
    };

    setToken = (token) => {
        this.setState({
            token: token,
        })
    };

    render() {
        if (this.state.token === null) {
            return (
                <SpotifyLogInButton setToken={this.setToken}/>
            )
        } else {
            return (
                <div style={{
                    backgroundColor: '#ffffff44',
                    padding: 16,
                    borderRadius: 16
                }}>
                    <SpotifyPlaylistFetcher
                        token={this.state.token}
                        generateChart={(data) => this.props.generateChart(data)}
                        code={this.props.code}
                        newBackgroundColor={this.props.newBackgroundColor}
                    />
                </div>
            )
        }
    }
}
