import React, {Fragment} from 'react';
import {Typography} from "@material-ui/core/index";
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip} from 'recharts';
import PropTypes from "prop-types";

export default class Graph extends React.Component {
    state = {
        dataDrivers: [],
        dataTeams: [],
    };

    static propTypes = {
        currentFile: PropTypes.object.isRequired,
        setFile: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.generateChartData();
    }

    sortDrivers = (drivers) => {
        let swap;
        let n = drivers.length - 1;
        let x = drivers;
        let circuitIndex = drivers[0].points.length - 1;

        do {
            swap = false;

            for (let i = 0; i < n; i++) {
                if (x[i].points[circuitIndex] < x[i + 1].points[circuitIndex]) {
                    let temp = x[i];
                    x[i] = x[i + 1];
                    x[i + 1] = temp;
                    swap = true;
                }
            }

            n--;
        } while (swap);

        return x;
    };

    sortTeams = (teams) => {
        let swap;
        let n = teams.length - 1;
        let x = teams;
        let circuitIndex = teams[0].points.length - 1;

        do {
            swap = false;

            for (let i = 0; i < n; i++) {
                if (x[i].points[circuitIndex] < x[i + 1].points[circuitIndex]) {
                    let temp = x[i];
                    x[i] = x[i + 1];
                    x[i + 1] = temp;
                    swap = true;
                }
            }

            n--;
        } while (swap);

        return x;
    };

    generateChartData = () => {
        let cf = this.props.currentFile;
        const dataDrivers = [];
        const dataTeams = [];

        this.sortDrivers(cf.drivers);
        this.sortTeams(cf.teams);

        for (let i = 0; i < cf.results.length + 1; i++) {
            if (i > 0 && cf.results[i - 1].results.length === 0) {
                break;
            } else {
                let raceObjectDriver = {};
                let raceObjectTeam = {};

                if (i === 0) {
                    raceObjectDriver.name = '';
                    raceObjectTeam.name = '';
                } else {
                    raceObjectDriver.name = cf.results[i - 1].countryAbbreviation;
                    raceObjectTeam.name = cf.results[i - 1].countryAbbreviation;
                }

                for (let j = 0; j < cf.drivers.length; j++) {
                    if (cf.drivers[j].team !== null) {
                        let abbr = cf.drivers[j].abbreviation;
                        raceObjectDriver[abbr] = cf.drivers[j].points[i]
                    }
                }

                for (let j = 0; j < cf.teams.length; j++) {
                    let team = cf.teams[j].name;
                    raceObjectTeam[team] = cf.teams[j].points[i]
                }

                dataDrivers.push(raceObjectDriver);
                dataTeams.push(raceObjectTeam);
            }
        }

        this.setState({
            dataDrivers: dataDrivers,
            dataTeams: dataTeams
        })
    };

    createLinesDrivers = () => {
        let cf = this.props.currentFile;
        let drivers = [];

        for (let i = 0; i < cf.drivers.length; i++) {
            drivers.push(cf.drivers[i]);
        }

        return drivers.map((driver, index) => {
            let teamColor = '#8884d8';

            for (let i = 0; i < cf.teams.length; i++) {
                if (cf.teams[i].name === driver.team) {
                    teamColor = cf.teams[i].color
                }
            }

            return (
                <Line
                    key={index}
                    type="linear"
                    dataKey={driver.abbreviation}
                    stroke={teamColor}
                    isAnimationActive={false}
                />
            )
        })
    };

    createLinesTeams = () => {
        let cf = this.props.currentFile;
        let teams = [];

        for (let i = 0; i < cf.teams.length; i++) {
            teams.push(cf.teams[i]);
        }

        return teams.map((team, index) => {
            return (
                <Line
                    key={index}
                    type="linear"
                    dataKey={team.name}
                    stroke={team.color}
                    isAnimationActive={false}
                />
            )
        })
    };

    render() {
        return (
            <Fragment>
                <Typography variant='h6'>
                    Driver Standings
                </Typography>
                <LineChart width={window.innerWidth * 0.9} height={400} data={this.state.dataDrivers}
                           margin={{top: 20, right: 20, bottom: 0, left: -20}}>
                    <CartesianGrid stroke="#666"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    {this.createLinesDrivers()}
                    <Tooltip contentStyle={{backgroundColor: '#454545'}}/>
                </LineChart>
                <Typography variant='h6' style={{marginTop: 20}}>
                    Team Standings
                </Typography>
                <LineChart width={window.innerWidth * 0.9} height={400} data={this.state.dataTeams}
                           margin={{top: 20, right: 20, bottom: 0, left: -20}}>
                    <CartesianGrid stroke="#666"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    {this.createLinesTeams()}
                    <Tooltip contentStyle={{backgroundColor: '#454545'}}/>
                </LineChart>
            </Fragment>
        )
    }
}
